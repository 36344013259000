import { FC } from 'react';
import { ASTRA_COLORS } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { mapActions } from 'store';
import { geocoderResultSelector } from 'store/slices/map/selectors';
import { TPosition } from 'types';

import { CustomMarker } from 'components/ui';

interface GeocoderResultMarkerProps {
  position: TPosition;
}

const GeocoderResultMarker: FC<GeocoderResultMarkerProps> = ({ position }) => {
  const dispatch = useAppDispatch();

  const handleClearGeocoderResult = () =>
    dispatch(mapActions.setGeocoderResult(undefined));

  useEscapeDown(handleClearGeocoderResult);

  return (
    <CustomMarker
      longitude={position[0]}
      latitude={position[1]}
      onClick={handleClearGeocoderResult}
      onContextMenu={handleClearGeocoderResult}
      color={ASTRA_COLORS.MAIN_PRODUCT}
    />
  );
};

export const GeocoderSource = () => {
  const geocoderResult = useAppSelector(geocoderResultSelector);

  if (!geocoderResult) {
    return null;
  }

  return <GeocoderResultMarker position={geocoderResult} />;
};
