import { convertCoordinates } from 'api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { TCoordsModeUnion } from 'types';

import { copyToClipboard } from 'utils';
import { formatWgsCoords, formatXYCoords } from 'utils/coordinates';

const getCustomCrsCoords = async (
  targetCrs: 'sk42' | 'usk2000',
  latitude: number,
  longitude: number
): Promise<{ x: number; y: number } | undefined> => {
  const data = await convertCoordinates('wgs', targetCrs, {
    b: latitude,
    l: longitude,
    h: DEFAULT_CONVERTER_HEIGHT,
  });

  if (data.to === targetCrs) {
    return { x: data.payload.x, y: data.payload.y };
  }
};

export const copyCoordinates = async (
  coordsMode: TCoordsModeUnion,
  latitude: number,
  longitude: number
) => {
  if (coordsMode === 'wgs') {
    copyToClipboard(formatWgsCoords(latitude, longitude));
  } else {
    const convertedCoords = await getCustomCrsCoords(
      coordsMode,
      latitude,
      longitude
    );

    if (convertedCoords) {
      copyToClipboard(formatXYCoords(convertedCoords.x, convertedCoords.y));
    }
  }
};
