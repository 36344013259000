import { MouseEvent as ReactMouseEvent, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as ChevronIcon } from 'images/icons/chevron-up.svg';

import TextInput from '../TextInput';

import { FilterCriteria, FilterCriteriaType } from './types';

import styles from './Filters.module.scss';

interface FilterCriteriaDropdownOption<T = unknown> {
  id?: number;
  type: FilterCriteriaType;
  label: string;
  value: T;
}

interface FilterCriteriaDropdownProps<T = unknown> {
  name: string;
  criteria: FilterCriteria | null;
  isOpen: boolean;
  options: FilterCriteriaDropdownOption<T>[];
  onSelect: (
    criteria: FilterCriteria | null,
    value: FilterCriteriaDropdownOption<T>
  ) => void;
  onArrowClick?: () => void;
}

export const FilterCriteriaDropdown = <T,>({
  name,
  criteria,
  options,
  isOpen,
  onSelect,
  onArrowClick,
}: FilterCriteriaDropdownProps<T>) => {
  const [search, setSearch] = useState('');
  const searchedOptions = options.filter((value) =>
    value.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleDropdownClick = (
    e: ReactMouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOptionClick = () => {
    onArrowClick?.();
    setSearch('');
  };

  const handleOptionSelect = (
    e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
    option: FilterCriteriaDropdownOption<T>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(criteria, option);
  };

  return (
    <div
      className={cn('tpg-c2 flex flex-col', styles['parameters-wrapper'])}
      onClick={handleDropdownClick}
    >
      <div
        className={cn(
          'flex flex-row justify-between',
          styles['parameter-header']
        )}
        onClick={handleOptionClick}
      >
        <span>{name}</span>
        <div className={styles['chevron-wrapper']}>
          <ChevronIcon
            className={cn(
              isOpen
                ? styles['chevron-wrapper__chevron__up']
                : styles['chevron-wrapper__chevron']
            )}
          />
        </div>
      </div>
      {isOpen && (
        <div className={cn('flex flex-row', styles['parameter-search'])}>
          <TextInput
            value={search}
            placeholder="Начните поиск"
            searchIcon
            size="s"
            theme="dark"
            inputClassName="tpg-c2"
            className={styles['search-input']}
            onChange={setSearch}
          />
        </div>
      )}
      {isOpen && (
        <div className={cn('flex flex-col', styles['parameter-search-values'])}>
          {searchedOptions.map((option, index) => (
            <div key={index} onClick={(e) => handleOptionSelect(e, option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
