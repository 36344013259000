import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  memo,
  PropsWithChildren,
  useState,
} from 'react';
import cn from 'classnames';
import { ElementThemeUnion } from 'constants/routes';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { ReactComponent as EyeOutlined } from 'images/icons/eye-full.svg';
import { ReactComponent as EyeClosed } from 'images/icons/eyeClosed.svg';
import { ReactComponent as Search } from 'images/icons/search.svg';

import './style.scss';

type InputEventType = React.ChangeEvent<HTMLInputElement>;

type InputSizeUnion = 's' | 'l' | 'm';

interface InputProps {
  onChange: (event: string) => void;
  className?: string;
  style?: CSSProperties;
  labelText?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  value: string | number;
  isReset?: boolean;
  searchIcon?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  isPassword?: boolean;
  autoFocus?: boolean;
  error?: string;
  onEnter?: () => void;
  onClick?: () => void;
  onBlur?: () => void;
  inputClassName?: string;
  size?: InputSizeUnion;
  theme?: ElementThemeUnion;
  borderOnFocus?: boolean;
  rounded?: boolean;
}

const TextInput = forwardRef(
  (
    {
      labelText = '',
      type = 'text',
      autoComplete = 'off',
      value,
      placeholder = '',
      style,
      className = '',
      isReset = false,
      searchIcon = false,
      readOnly = false,
      isPassword = false,
      autoFocus = false,
      error = '',
      maxLength = 150,
      onChange,
      onEnter,
      children,
      onClick,
      onBlur,
      inputClassName,
      size = 'l',
      theme = 'light',
      borderOnFocus = false,
      rounded = true,
    }: PropsWithChildren<InputProps>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [visible, setVisible] = useState(false);
    const [focus, setFocus] = useState(false);

    const handleChange = (e: InputEventType) => {
      if (readOnly) return;
      onChange(e.target.value);
    };

    const handleBlur = (e: InputEventType) => {
      e.target.value = e.target.value.trim();

      setFocus(false);

      if (value !== e.target.value) {
        onChange(e.target.value);
      }

      onBlur?.();
    };

    const handleReset = () => {
      onChange('');
    };

    const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
      if (key === 'Enter') {
        onEnter && onEnter();
      }
    };

    const handleVisible = () => {
      setVisible((visible) => !visible);
    };

    return (
      <div
        className={`flex-col items-center justify-start ${className}`}
        style={style}
      >
        {labelText && (
          <label
            className={cn('tpg-c2 input__label', {
              'input__label-readOnly': readOnly,
            })}
          >
            {labelText}
          </label>
        )}
        <div
          className={cn('w-full relative', {
            'border border-solid border-1 border-bright':
              focus && borderOnFocus,
          })}
        >
          {searchIcon && (
            <Search
              className={cn('input__search-icon', {
                'ml-1.5 h-4 w-4': size === 's',
                'ml-3 h-4 w-4': size === 'm',
                'ml-4 h-5 w-5': size === 'l',
              })}
            />
          )}
          <input
            ref={ref}
            className={cn(
              'text-tpg_title input__text w-full border-none ',
              {
                'input__search-s': searchIcon && size === 's',
                'input__search-m': searchIcon && size === 'm',
                'input__search-l': searchIcon && size === 'l',
                'pl-8': isReset || isPassword,
                'opacity-50': readOnly,
                'rounded-none': !rounded,
                'tpg-input h-5 rounded-sm': size === 's',
                'tpg-input h-8  rounded-md': size === 'm',
                'tpg-input h-10 rounded-md': size === 'l',
                'bg-dark': theme === 'dark',
                'bg-light': theme === 'light',
                'bg-ultrablack': theme === 'ultrablack',
              },
              inputClassName
            )}
            type={isPassword && !visible ? 'password' : type}
            value={value}
            autoFocus={autoFocus}
            placeholder={placeholder}
            autoComplete={autoComplete}
            readOnly={readOnly}
            maxLength={maxLength}
            min={type === 'number' ? 0 : undefined}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onClick={onClick}
            onFocus={() => setFocus(true)}
          />
          {isPassword && (
            <span className="input__password" onClick={handleVisible}>
              {visible ? <EyeOutlined /> : <EyeClosed />}
            </span>
          )}
          {isReset && value && (
            <button className="input__reset" onClick={handleReset}>
              <CloseIcon className="input__close-icon" />
            </button>
          )}
          {children && (
            <div
              className={cn('icon-container', {
                'input__children-s': size === 's',
                'input__children-m': size === 'm',
                'input__children-l': size === 'l',
              })}
            >
              {children}
            </div>
          )}
        </div>
        {error && <span className="tpg-alert error">{error}</span>}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default memo(TextInput);
