import { FC, useState } from 'react';

import { Segmented } from 'components/ui/index';

import './style.scss';
interface ISidebarWindowWithTabs {
  tabs: JSX.Element[];
  tabOptions: string[];
  hideOptions?: boolean;
}
const SidebarWindowWithTabs: FC<ISidebarWindowWithTabs> = ({
  tabs,
  tabOptions,
  hideOptions = false,
}) => {
  const [selectedTab, setTab] = useState<string>(tabOptions[0]);
  const renderSelectedTab = () =>
    tabs[tabOptions.findIndex((option) => option === selectedTab)];

  return (
    <div className="sidebar-window flex flex-col h-full">
      <div className="sidebar-window__header">
        {!hideOptions && (
          <Segmented
            options={tabOptions}
            active={selectedTab}
            onChange={setTab}
          />
        )}
      </div>
      <>{renderSelectedTab()}</>
    </div>
  );
};

export default SidebarWindowWithTabs;
