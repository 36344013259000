import { SyntheticEvent, useState } from 'react';
import Highlighter from 'react-highlight-words';
import cn from 'classnames';
import { ReactComponent as ChevronDown } from 'images/newIcons/down.svg';
import { ReactComponent as ChevronUp } from 'images/newIcons/up.svg';

import { ISelectOption } from '../../../interfaces';

interface SelectItemProps<T> {
  value: T;
  handleClick: (value: T) => void;
  option: ISelectOption;
  searchValue: string;
  depth: number;
  isExpandable: boolean;
}

const SelectItem = <T,>({
  value,
  handleClick,
  option,
  searchValue,
  depth,
  isExpandable,
}: SelectItemProps<T>) => {
  const [isExpanded, setExpanded] = useState(false);

  const showChevron = isExpandable && !!option.children?.length;

  const searchValueNotEmpty = !!searchValue;

  const showSubOptions = showChevron && (isExpanded || searchValueNotEmpty);

  const CurrentChevron = isExpanded ? ChevronUp : ChevronDown;

  const handleChevronClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const renderItem = (option: ISelectOption, depth = 1) => (
    <div
      onClick={() => handleClick(option.value as T)}
      style={{ paddingLeft: 16 * depth }}
      className={cn('select__options__item__label', {
        select__options__item__label__selected: value === option.value,
      })}
    >
      <div>
        <Highlighter
          highlightClassName="highlighted tpg-c2"
          unhighlightClassName="unhighlighted tpg-c2"
          searchWords={[searchValue]}
          autoEscape={true}
          textToHighlight={option.label}
        />
      </div>
      {showChevron && (
        <CurrentChevron
          className={'group-hover icon-container'}
          onClick={handleChevronClick}
        />
      )}
    </div>
  );

  return (
    <li
      id={`option-${option.value}`}
      className={cn('select__options__item', {
        'select__options__item__sub-item': option.children?.length,
      })}
    >
      {renderItem(option, depth)}
      {showSubOptions && (
        <ul className="select__options_sublayer">
          {option.children?.map((suboption, idx) => (
            <SelectItem
              key={idx}
              value={value}
              handleClick={handleClick}
              option={suboption}
              searchValue={searchValue}
              depth={depth + 1}
              isExpandable={isExpandable}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default SelectItem;
