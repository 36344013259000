import * as React from 'react';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import Tooltip from 'rc-tooltip';

import { Button, TextInput } from 'components/ui';

import { searchDumps } from '../../api/dumps';
import { Header } from '../../components/Header/Header';
import DumpViewModal from '../../components/Map/DumpViewModal/DumpViewModal';
import { MaigretUserSearchModal } from '../../components/Map/MaigretUserSearchModal/MaigretUserSearchModal';
import AstraTable, {
  AstraTableFetchParams,
  getTablePagingInitialParamsBasedOnUrl,
} from '../../components/ui/Table/AstraTable';
import { AstraTableHeadCell } from '../../components/ui/Table/AstraTableHeader';
import { DumpSearchTableDocument } from '../../interfaces/dumps';
import { TDumpSearchSortDirectionUnion } from '../../types/dumps';
import {
  getDumpDocumentPersonFullName,
  getDumpDocumentPersonShortName,
  getDumpDocumentsText,
} from '../../utils/dumpUtils';

import './style.scss';

const TABLE_HEAD_CELLS: AstraTableHeadCell<TDumpSearchSortDirectionUnion>[] = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: false,
    label: 'ФИО',
  },
  {
    id: 'birthDate',
    sortableProperty: 'birthday',
    numeric: false,
    disablePadding: false,
    label: 'Дата рождения',
  },
  {
    id: 'documents',
    numeric: false,
    disablePadding: false,
    label: 'Документы',
  },
  {
    id: 'addresses',
    numeric: false,
    disablePadding: false,
    label: 'Адреса',
  },
  {
    id: 'sources',
    numeric: false,
    disablePadding: false,
    label: 'Источники',
  },
];
const DumpsScreen = () => {
  const [currentDump, setCurrentDump] = useState<
    DumpSearchTableDocument | undefined
  >();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const initialTableParamsFromUrl = getTablePagingInitialParamsBasedOnUrl(
    searchParams,
    ''
  );
  const [searchQuery, setSearchQuery] = useState(
    initialTableParamsFromUrl.query
  );

  const fetchRows = useMemo(
    () =>
      async (params: AstraTableFetchParams<TDumpSearchSortDirectionUnion>) => {
        const response = await searchDumps({
          start: params.start,
          size: params.size,
          sort_by: params.sortBy,
          direction: params.sortDirection,
          query: (params.searchQuery || '').trim(),
        });
        return {
          rows: response.documents,
          total: response.total_count,
        };
      },
    []
  );

  const renderRowCells: (
    row: DumpSearchTableDocument,
    index: string
  ) => React.ReactNode = (row, index) => {
    const documentsColumnText = getDumpDocumentsText(row.document);
    const addressesColumnText = (row.address || []).reduce(
      (acc, addr) => `${acc}\n${addr.value}`,
      ''
    );

    return (
      <>
        <TableCell
          component="th"
          id={`astra-table-checkbox-${index}`}
          scope="row"
          padding="normal"
        >
          <div className="feature-table__row__user">
            <div className="feature-table__row__user__details">
              <Tooltip
                overlayClassName="feature-table__row__tooltip"
                placement="top"
                overlay={
                  <span>
                    {row.name ? getDumpDocumentPersonFullName(row.name) : ''}
                  </span>
                }
              >
                <p className="colored-title">
                  {row.name ? getDumpDocumentPersonShortName(row.name) : ''}
                </p>
              </Tooltip>
            </div>
          </div>
        </TableCell>
        <TableCell align="right">
          <div className="feature-table__row__description colored-title">
            {row.birthday || ''}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {documentsColumnText}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {addressesColumnText}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="column-with-newlines colored-title">
            {row.sources.join(', ')}
          </div>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Header className="w-full">
        <TextInput
          searchIcon
          isReset
          className="ml-4"
          style={{ width: 370 }}
          placeholder="Поиск по таблице..."
          onChange={setSearchQuery}
          value={searchQuery}
        />
      </Header>
      <div className="users-list">
        <div className="users-list__list">
          {/*<TextInput*/}
          {/*  searchIcon*/}
          {/*  isReset*/}
          {/*  placeholder="Поиск по таблице..."*/}
          {/*  onChange={setSearch}*/}
          {/*  value={search}*/}
          {/*/>*/}
          <Button
            className="py-2 px-8"
            title={'+ Искать по юзернейму'}
            onClick={() => setModalOpen(true)}
          />
          {/*<FiltersGroup*/}
          {/*  list={featureFilters}*/}
          {/*  filters={filters}*/}
          {/*  onSetFilterValue={onSetFilterValue}*/}
          {/*  isAccessFilterByClient={false}*/}
          {/*/>*/}
        </div>
        <div className="users-list__table">
          <AstraTable
            searchQuery={searchQuery}
            handleRowClick={(row) => {
              setModalOpen(true);
              setCurrentDump(row);
            }}
            fetchRows={fetchRows}
            // handleSelectedRows={(rows) => {}}
            rowIdExtractor={(row) => row.id}
            renderRowCells={(row, props) => renderRowCells(row, props.key)}
            headCells={TABLE_HEAD_CELLS}
          />
        </div>
        {modalOpen && !currentDump && (
          <MaigretUserSearchModal onClose={() => setModalOpen(false)} />
        )}
        {modalOpen && currentDump && (
          <DumpViewModal
            dump={currentDump}
            onClose={() => {
              setCurrentDump(undefined);
              setModalOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default DumpsScreen;
