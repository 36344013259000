import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useEscapeDownControlled } from 'hooks/useEscapeDown';

import { TextInput } from 'components/ui';

import { TRenderEditingName } from './types';

interface IEditableNameProps {
  value: string;
  editable?: boolean;
  editing?: boolean;
  className?: string;
  renderEditingName?: TRenderEditingName;
  onEditingChange?: (editing: boolean) => void;
  onEdit?: (value: string) => void;
}

export const EditableName: FC<PropsWithChildren<IEditableNameProps>> = ({
  value,
  editable,
  editing,
  className,
  children,
  renderEditingName,
  onEditingChange,
  onEdit,
}) => {
  const [editableValue, setEditableValue] = useState(value);

  const onEditOpen = () => {
    if (!editable) {
      return;
    }

    onEditingChange?.(true);
  };

  const onChange = (e: string) => setEditableValue(e);

  const onSubmit = () => {
    onEditingChange?.(false);
    onEdit?.(editableValue);
  };

  const onCancel = useCallback(() => {
    onEditingChange?.(false);
    setEditableValue(value);
  }, [value]);

  const [subscribeToEscapeDown, unsubscribeFromEscapeDown] =
    useEscapeDownControlled(onCancel);

  useEffect(
    () => () => unsubscribeFromEscapeDown(),
    [unsubscribeFromEscapeDown]
  );

  useEffect(() => {
    if (editing) {
      subscribeToEscapeDown();
    } else {
      unsubscribeFromEscapeDown();
    }
  }, [editing, subscribeToEscapeDown, unsubscribeFromEscapeDown]);

  return (
    <div
      className={cn('w-full', editable && 'cursor-pointer', className)}
      onDoubleClick={onEditOpen}
    >
      {editing
        ? renderEditingName?.(editableValue, onChange, onSubmit, onCancel) || (
            <TextInput
              value={editableValue}
              autoFocus
              size="s"
              inputClassName="!border !border-solid !border-bright !px-[6px] !py-[3px] tpg-c1 !text-tpg_base"
              onChange={onChange}
              onEnter={onSubmit}
              onBlur={onCancel}
            />
          )
        : children}
    </div>
  );
};
