import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addMediaToEntity,
  deleteEntityNoHandle,
  getEntitiesCounters,
  getPredefinedTemplate,
  relinkEntity,
  searchEntityById,
  searchMapEntities,
  upsertEntityNoHandle,
} from 'api/entities';
import { predefinedTemplates, ROOT_HIERARCHY_ID } from 'constants/entities';
import { errorMessages } from 'constants/errors';
import { MAP_ENTITIES_LOADER } from 'constants/loaders';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  GetEntityCountersRequest,
  IEntityAddMediaRequest,
  IEntitySearchRequest,
} from 'interfaces/entity';
import { MAP_V2_REDUCER_NAMES } from 'store/slices/mapV2/constants';
import { loadersActions } from 'store/slices/service/loadersSlice';
import { AsyncThunkConfig } from 'types';
import {
  Entity,
  EntityListResponse,
  GetEntityCountersResponse,
  PredefinedTemplate,
  UpsertEntityRequest,
  UpsertEntityResponse,
} from 'types/entities';

import { buildThunk } from 'utils';

import { TABS_REDUCER_NAMES } from '../../constants';
import { LAYERS_REDUCER_NAMES } from '../constants';

import {
  GetMapEntituesThunkData,
  GetMapEntityChildrenThunkData,
  RelinkEntityThunkData,
} from './types';

export const getPredefinedTemplateThunk = buildThunk<
  PredefinedTemplate,
  predefinedTemplates
>(
  `${reducersNames.MAP_V2}`,
  errorMessages.GET_PREDEFINED_TEMPLATES_ERROR,
  getPredefinedTemplate
);

export const getMapEntitiesThunk = buildThunk<
  EntityListResponse,
  GetMapEntituesThunkData
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_ENTITIES}`,
  errorMessages.GET_MAP_ENTITIES_ERROR,
  searchMapEntities,
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(
      loadersActions.addLoader({
        id: MAP_ENTITIES_LOADER,
        loading: true,
        error: null,
      })
    ),
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(loadersActions.removeLoader(MAP_ENTITIES_LOADER))
);

export const getMapFilteredEntitiesThunk = buildThunk<
  EntityListResponse,
  IEntitySearchRequest
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_FILTERED_ENTITIES}`,
  errorMessages.GET_MAP_FILTERED_ENTITIES_ERROR,
  searchMapEntities,
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(
      loadersActions.addLoader({
        id: MAP_ENTITIES_LOADER,
        loading: true,
        error: null,
      })
    ),
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(loadersActions.removeLoader(MAP_ENTITIES_LOADER))
);

export const getMapEntityChildrenThunk = buildThunk<
  EntityListResponse,
  GetMapEntityChildrenThunkData
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_ENTITY_CHILDREN}`,
  errorMessages.GET_MAP_ENTITY_CHILDREN_ERROR,
  searchMapEntities
);

export const upsertEntityThunk = buildThunk<
  UpsertEntityResponse,
  UpsertEntityRequest
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.UPSERT_ENTITY}`,
  errorMessages.ENTITY_CREATION_ERROR,
  // todo switch to function with proper naming
  upsertEntityNoHandle
);

export const deleteEntityThunk = buildThunk<number, number>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.DELETE_ENTITY}`,
  errorMessages.ENTITY_DELETE_ERROR,
  // todo switch to function with proper naming
  deleteEntityNoHandle
);

export const addMediaToEntityThunk = buildThunk<Entity, IEntityAddMediaRequest>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.ADD_MEDIA_TO_ENTITY}`,
  errorMessages.ENTITY_ADD_MEDIA_ERROR,
  addMediaToEntity
);

export const getMapEntityCountersThunk = buildThunk<
  GetEntityCountersResponse,
  GetEntityCountersRequest
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_ENTITY_COUNTERS}`,
  errorMessages.GET_MAP_ENTITY_COUNTERS_ERROR,
  getEntitiesCounters
);

export const relinkEntityThunk = createAsyncThunk<
  unknown,
  RelinkEntityThunkData,
  AsyncThunkConfig
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.RELINK_ENTITY}`,
  async (params, thunkAPI) => {
    try {
      // Eliminates the possibility of drag-and-drop the root folder to the root, which changes the folder owner
      if (params.newParentEntityId === params.oldParentEntityId) return;

      const isMovingToRoot = params.newParentEntityId === ROOT_HIERARCHY_ID;

      await relinkEntity(
        params.entityId,
        isMovingToRoot ? undefined : params.newParentEntityId
      );

      thunkAPI.dispatch?.(
        getMapEntitiesThunk({ maxDepth: 999, refetch: true })
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error,
        message: errorMessages.RELINK_ENTITY_ERROR,
      });
    }
  }
);

export const searchEntityByIdThunk = buildThunk<EntityListResponse, string>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.SEARCH_ENTITY_BY_ID}`,
  errorMessages.GET_MAP_ENTITIES_ERROR,
  searchEntityById,
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(
      loadersActions.addLoader({
        id: MAP_ENTITIES_LOADER,
        loading: true,
        error: null,
      })
    ),
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(loadersActions.removeLoader(MAP_ENTITIES_LOADER))
);
