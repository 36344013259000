import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AsyncThunkConfig, ThunkAPI, ThunkRejectValue } from 'types';

import { notify } from 'utils';

// Respons(E) - интерфейс ответа
// Reques(T) - интерфейс запроса

export const createThunk = <E, T = undefined>(
  name: string,
  axiosRequest: (data: T) => Promise<AxiosResponse<E>>,
  errorMessage?: string,
  callback?: (data: E, request?: T) => E
) =>
  createAsyncThunk<E, T>(name, async (request, thunkAPI) => {
    try {
      const { data } = await axiosRequest(request);

      if (callback) {
        return callback(data, request);
      }

      return data;
    } catch (error) {
      if (errorMessage) {
        notify.error(errorMessage);
      }

      return thunkAPI.rejectWithValue(error);
    }
  });

// TODO: replace createThunk by buildThunk
export const buildThunk = <E, T = undefined>(
  name: string,
  errorMessage: string,
  axiosRequest: (data: T) => Promise<AxiosResponse<E>>,
  enableLoader?: (request: T, thunkAPI: ThunkAPI) => void,
  disableLoader?: (request: T, thunkAPI: ThunkAPI) => void,
  handleLoaderOnError?: (
    rejectValue: ThunkRejectValue,
    request: T,
    thunkAPI: ThunkAPI
  ) => void,
  modifyRequestData?: (data: E, request?: T) => E
) =>
  createAsyncThunk<E, T, AsyncThunkConfig>(name, async (request, thunkAPI) => {
    try {
      enableLoader?.(request, thunkAPI);

      const { data } = await axiosRequest(request);

      disableLoader?.(request, thunkAPI);

      if (modifyRequestData) {
        return modifyRequestData(data, request);
      }

      return data;
    } catch (error) {
      handleLoaderOnError?.(
        { error, message: errorMessage },
        request,
        thunkAPI
      );

      return thunkAPI.rejectWithValue({
        error,
        message: errorMessage,
      });
    }
  });
