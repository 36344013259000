import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { monitoringRoutes } from 'constants/routes';
import { useAppDispatch, useAppSelector, useFilters } from 'hooks';
import { ITag } from 'interfaces';
import { monitoringActions } from 'store';
import {
  getBookmarkIdsThunk,
  getParsersThunk,
} from 'store/slices/monitoring/actions';
import { mediaFilesSelector } from 'store/slices/monitoring/selectors';
import { TFilterType, TPublicationUnion, TSourceUnion } from 'types';

import InfiniteList from 'components/InfiniteList';
import { MediaGalleryModal } from 'components/MediaGalleryModal';
import Sidebar from 'components/Monitoring/Sidebar';
import TagContainer from 'components/TagContainer';
import { TextInput } from 'components/ui';
import { getMediaFiles } from 'utils';

import { Header } from '../../components/Header/Header';

import './style.scss';

const getTitleFromPath = (path: TFilterType) => {
  switch (path) {
    case 'parser':
      return 'ПАРСЕР';
    case 'source':
      return 'ИСТОЧНИК';
    case 'media':
      return 'МЕДИА';
    case 'post':
      return 'ТИП ПУБЛИКАЦИИ';
    case 'date':
      return 'ДАТА';
    case 'id':
      return 'ЗАКЛАДКИ';
    default:
      return '';
  }
};

const MonitoringPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { onChangeSearchValue, tags, setPage } = useFilters();
  const links = useAppSelector(mediaFilesSelector);

  const [inputValue, setInputValue] = useState('');

  const handleRemoveTag = useCallback(
    ({ value }: Omit<ITag, 'path'>, path: string) => {
      if (path === 'source' || path === 'post') {
        const filterValue = value as TSourceUnion | TPublicationUnion;

        dispatch(
          monitoringActions.toggleCheckbox({ field: path, value: filterValue })
        );
        return;
      }

      if (path === 'media') {
        dispatch(monitoringActions.toggleMediaCheckbox(value));
        return;
      }

      if (path === 'date') {
        dispatch(monitoringActions.resetDateRange());
        return;
      }

      if (path === 'parser') {
        dispatch(monitoringActions.resetParsers());
        return;
      }

      if (path === 'id') {
        dispatch(monitoringActions.resetBookmarks());
        navigate(monitoringRoutes.FEED);
        return;
      }

      dispatch(
        monitoringActions.onChangeDynamicFilters({
          path,
          value: false,
        })
      );
    },
    []
  );

  useEffect(() => {
    dispatch(getBookmarkIdsThunk());
    dispatch(getParsersThunk());

    return () => {
      dispatch(monitoringActions.resetMonitoringState());
    };
  }, []);

  const mediaFiles = useMemo(() => getMediaFiles(links), [links]);

  const handleSearch = useCallback(() => {
    onChangeSearchValue(inputValue);
  }, [inputValue, onChangeSearchValue]);

  const handleCloseModal = useCallback(() => {
    dispatch(monitoringActions.setMediaFiles({ files: [] }));
  }, []);

  return (
    <>
      <Header />
      <section className={'monitoring'}>
        <Sidebar />
        <div className="monitoring__container">
          <div className="monitoring__container__header flex flex-col justify-start">
            <TextInput
              value={inputValue}
              placeholder="Поиск по ключевым словам"
              searchIcon
              maxLength={99999}
              onEnter={handleSearch}
              onChange={setInputValue}
              className="w-full"
            />
            <TagContainer
              tags={tags.map((t) => ({
                value: t.value,
                label: t.path == 'id' ? '' : t.label,
                onRemove: () => handleRemoveTag(t, t.path),
                title: getTitleFromPath(t.path),
              }))}
            />
          </div>
          <div className="monitoring__title-container">
            <div className="monitoring__title-container__header">
              <h1 className="tpg-title-one">Лента результатов</h1>
            </div>
            <InfiniteList searchValue={inputValue} setPage={setPage} />
          </div>
          {!!mediaFiles.length && (
            <MediaGalleryModal
              mediaFiles={mediaFiles}
              containerClassName="!absolute"
              onClose={handleCloseModal}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default MonitoringPage;
