import { FC, memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  buildReport,
  deleteEntity,
  getAllEntityTemplateParameters,
  upsertEntity,
} from 'api/entities';
import cn from 'classnames';
import { errorMessages, successMessages } from 'constants/errors';
import { DESCRIPTION_PARAMETER_KEY } from 'constants/parameters';
import { entitiesRoutes } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { ReactComponent as Close } from 'images/newIcons/close.svg';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';
import { ReactComponent as Report } from 'images/newIcons/report.svg';
import { ReactComponent as Rollback } from 'images/newIcons/rollback.svg';
import { currentEntitySelector } from 'store/slices/map/selectors';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { Entity, EntityParameter } from 'types/entities';

import CustomMarkdownEditor from 'components/ui/MardownEditor';
import TextInput from 'components/ui/TextInput';
import { notify } from 'utils';

import { EntityControl } from './EntityControl';
import { prepareUpsertEntityReqFromRows } from './utils';

interface TableEntityCardProps {
  className?: string;
}

enum ETableEntityTabs {
  DESCRIPTION = 'Описание',
  LOCATION = 'Расположение',
}

const TableEntityLinks = [
  ETableEntityTabs.DESCRIPTION,
  ETableEntityTabs.LOCATION,
];

export type TTableEntityRow = { key: string; value: string };

const mockReportParams = { ID: 0, clientCode: '', encodedSK42: '' };

export const TITLE_KEY = 'Наименование';

const TableEntityCard: FC<TableEntityCardProps> = ({ className }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const entity = useAppSelector(currentEntitySelector);
  const mapObjectTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, 'mapObject')
    ) || 0;

  const getTableEntityRows = (
    entity: Entity | undefined
  ): TTableEntityRow[] => {
    if (!entityParameters) return [];

    const rows: TTableEntityRow[] = [
      {
        key: TITLE_KEY,
        value: entity?.title || '',
      },
    ];

    const createRow = (key: string, value: string): TTableEntityRow => ({
      key: key,
      value: value,
    });

    if (!entity)
      return [
        ...rows,
        ...entityParameters.map((el) => createRow(el.title, '')),
      ];

    const paramRows = entityParameters.map((parameter) =>
      createRow(parameter.title, entity.parameters[parameter.id].value)
    );

    return [...rows, ...paramRows];
  };

  const [entityParameters, setEntityParameters] = useState<EntityParameter[]>();
  const [tableEntityRows, setTableEntityRows] = useState<TTableEntityRow[]>(
    entity ? getTableEntityRows(entity) : []
  );
  const [selectedTab, setTab] = useState<ETableEntityTabs>(
    ETableEntityTabs.DESCRIPTION
  );
  const [description, setDescription] = useState(
    tableEntityRows.find((item) => item.key === DESCRIPTION_PARAMETER_KEY)
      ?.value || ''
  );

  const [isDescriptionSet, setDescriptionSet] = useState(false);

  useEffect(() => {
    const fetchTemplateParameters = async () => {
      const response = await getAllEntityTemplateParameters(
        mapObjectTemplateId
      );
      setEntityParameters(response);
    };
    fetchTemplateParameters();
  }, []);

  useEffect(() => {
    setTableEntityRows(getTableEntityRows(entity || undefined));
  }, [entityParameters]);

  useEffect(() => {
    const descriptionRow = tableEntityRows.find(
      (item) => item.key === DESCRIPTION_PARAMETER_KEY
    );
    if (descriptionRow) {
      setDescription(descriptionRow.value);
      setDescriptionSet(true);
    }
  }, [tableEntityRows]);

  const handleMarkdownChange = (v: string | undefined) => {
    const updatedDescription = v || '';
    setDescription(updatedDescription);
    const updatedTableEntityRows = tableEntityRows.map((row) =>
      row.key === DESCRIPTION_PARAMETER_KEY
        ? { ...row, value: updatedDescription }
        : row
    );

    setTableEntityRows(updatedTableEntityRows);
  };

  const getTableEntityTabByTitle = (title: ETableEntityTabs) => {
    switch (title) {
      case ETableEntityTabs.DESCRIPTION:
        return isDescriptionSet ? (
          <CustomMarkdownEditor
            value={description || ''}
            onChange={handleMarkdownChange}
          />
        ) : (
          <div>Loading...</div>
        );
      case ETableEntityTabs.LOCATION:
        return <div>Здесь должны быть фотографии и галаерея штук</div>;
      default:
        return <div />;
    }
  };

  const addRow = () => {
    // TODO update after add parameter generation functional
    setTableEntityRows([...tableEntityRows, { key: '', value: '' }]);
  };

  const handleInputChange = (index: number, key: string, value: string) => {
    const updatedTableEntityRows = [...tableEntityRows];
    updatedTableEntityRows[index] = { key, value };

    setTableEntityRows(updatedTableEntityRows);
  };

  const renderLink = (title: ETableEntityTabs) => (
    <div
      className={cn(
        'header__links header-link__title',
        title === selectedTab && '!text-bright_product'
      )}
      onClick={() => setTab(title)}
    >
      {title}
    </div>
  );

  const renderEntityHeader = () => (
    <div className="table-entity__header flex flex-row justify-between tpg-c1 h-10 px-3">
      {entity ? entity.title : 'Создать объект'}
      <div className="flex flex-row gap-4">
        {TableEntityLinks.map((link) => renderLink(link))}
      </div>
    </div>
  );

  const renderParamsTable = () => {
    const handleRenderRow = (item: TTableEntityRow, index: number) => {
      if (item.key === DESCRIPTION_PARAMETER_KEY) {
        return null;
      }
      return (
        <div
          key={item.key}
          className={cn(
            'table-entity__params-table__row flex items-center',
            index !== 0 && 'table-entity__params-table__row__with-border'
          )}
        >
          <div className="table-entity__params-table__cell flex items-center tpg-c1 w-1/2 h-full px-3">
            {item.key}
          </div>
          <div className="w-1/2">
            <TextInput
              value={item.value} // todo add toStringParamValueByType
              onChange={(e) => handleInputChange(index, item.key, e)}
              theme="ultrablack"
              size="s"
              inputClassName="tpg-c1"
            />
          </div>
        </div>
      );
    };

    return (
      <div className="pt-4">
        <div className="table-entity__params-table">
          {tableEntityRows.map((item, index) => handleRenderRow(item, index))}
        </div>
      </div>
    );
  };

  const renderEntityBody = () => {
    return (
      <div className="table-entity__body flex w-full">
        <div className="w-full p-4">
          <div className="flex flex-row justify-between align-center">
            <div className="tpg-b2">Основная информация</div>
            {/*<div className="icon-container" onClick={addRow}>*/}
            {/*  <Plus />*/}
            {/*</div>*/}
          </div>
          {renderParamsTable()}
        </div>
        <div className="table-entity__body__right w-1/2 max-w-[700px]">
          {getTableEntityTabByTitle(selectedTab)}
        </div>
      </div>
    );
  };

  const handleSaveEntity = () => {
    if (!entityParameters) return;
    const upsertEntityReq = prepareUpsertEntityReqFromRows(
      tableEntityRows,
      entityParameters,
      entity?.id
    );
    upsertEntity(upsertEntityReq)
      .then((response) => {
        notify.success(
          entity?.id
            ? successMessages.ENTITY_UPDATE_SUCCESS
            : successMessages.ENTITY_CREATION_SUCCESS
        );
        if (pathname === entitiesRoutes.NEW_ENTITY) {
          navigate(`${entitiesRoutes.TABLE}/${response.id}`);
        }
      })

      .catch(() =>
        notify.error(
          entity?.id
            ? errorMessages.ENTITY_UPDATE_ERROR
            : errorMessages.ENTITY_CREATION_ERROR
        )
      );
  };

  const handleDeleteEntity = async (id: number | undefined) => {
    if (!id) return;
    await deleteEntity(id);
  };

  // TODO update mocked parameters
  const renderEntityFooter = () => (
    <div className="table-entity__footer w-full flex flex-row justify-between tpg-c2 h-10 px-3">
      <div className="flex flex-row gap-3">
        <EntityControl
          title="Сделать отчет"
          icon={<Report />}
          onClick={() => buildReport(mockReportParams)}
        />
        <EntityControl
          title="Открыть в таблице"
          icon={<Report />}
          onClick={() => console.log('open in entities table')}
        />
      </div>
      <div className="flex flex-row gap-3">
        <EntityControl
          title="Удалить"
          icon={<Close />}
          onClick={() => handleDeleteEntity(entity?.id)}
        />
        <EntityControl
          title="Отменить"
          icon={<Rollback />}
          onClick={() => console.log('undo action')}
        />
        <EntityControl
          title="Сохранить объект"
          icon={<Plus />}
          onClick={handleSaveEntity}
        />
      </div>
    </div>
  );

  return (
    <div className={cn('m-12 table-entity flex flex-col h-full', className)}>
      {renderEntityHeader()}
      {renderEntityBody()}
      {renderEntityFooter()}
    </div>
  );
};

export default memo(TableEntityCard);
