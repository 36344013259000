import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as CopyIcon } from 'images/newIcons/copy.svg';
import { ISelectOption } from 'interfaces';

import TreeSelect from 'components/ui/TreeSelect';
import { copyToClipboard } from 'utils';

import styles from './object-preview.module.scss';

export interface IObjectPreviewTableRow {
  label: string;
  value: string;
  selectProps?: {
    options: ISelectOption[];
    handler: (v: any) => void;
  };
  copyable?: boolean;
}

export interface IObjectPreviewTableProps {
  rows: IObjectPreviewTableRow[];
  classNames?: { cell?: string };
  firstCellWidth?: string;
}

export const ObjectPreviewTable: FC<IObjectPreviewTableProps> = ({
  rows,
  classNames,
  firstCellWidth,
}) => (
  <table className="w-[calc(100%+2px)] m-[-1px] border-collapse tpg-b2 text-tpg_base">
    <tbody>
      {rows.map(
        (row, idx) =>
          row.value && (
            <tr
              key={`${row.label}-${idx}`}
              className="border border-solid border-tpg_light border-t-0 border-l-0"
            >
              <td
                className={`px-3 py-1 align-middle border-r border-solid border-tpg_light`}
                style={{ width: firstCellWidth }}
              >
                <span
                  className={cn(
                    'line-clamp-3 whitespace-pre-line',
                    classNames?.cell
                  )}
                >
                  {row.label}
                </span>
              </td>
              <td className="px-3 py-1 text-tpg_title">
                {row.selectProps ? (
                  <TreeSelect
                    menuPlacement="top"
                    value={row.value}
                    options={row.selectProps.options}
                    withEmpty={false}
                    classNames={{
                      body: '!p-0 !h-6',
                      text: 'text-tpg_title',
                      options: '!top-[-120px]',
                    }}
                    onSelect={row.selectProps?.handler}
                  />
                ) : (
                  <div className="flex justify-between items-center">
                    <span
                      className={cn(
                        // TODO: temporary solution, replace it later with 'overflow-hidden'
                        styles['scrollbar_hidden'],
                        'whitespace-pre-line overflow-scroll line-clamp-6',
                        classNames?.cell
                      )}
                    >
                      {row.value}
                    </span>
                    {row.copyable && (
                      <CopyIcon
                        className="w-5 h-5 shrink-0 cursor-pointer [&>path]:fill-tpg_light [&>path]:hover:fill-dark_product"
                        onClick={() => copyToClipboard(row.value)}
                      />
                    )}
                  </div>
                )}
              </td>
            </tr>
          )
      )}
    </tbody>
  </table>
);
