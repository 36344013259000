import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import {
  MAP_ENTITY_FILTERABLE_PARAMS,
  MAP_ENTITY_PARAM_VALUES,
  predefinedTemplates,
} from 'constants/entities';
import { MAP_ENTITIES_LOADER } from 'constants/loaders';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FilterCriteria } from 'interfaces/entity';
import { getMapFilteredEntitiesThunk } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import {
  entitiesMapSelector,
  entityCountersMapSelector,
  hierarchySelector,
  predefinedTemplateSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { loaderSelector } from 'store/slices/service/loadersSlice/selectors';
import { EntityParameter } from 'types/entities';
import { useDebouncedCallback } from 'use-debounce';

import { AccessControlDetailsProps } from 'components/Access/AccessControlDetails';
import { LayerDetailsProps } from 'components/Map/LayerDetails';
import { Loader, TextInput } from 'components/ui';
import { FilterDropdown } from 'components/ui/Filters';
import { ActiveFilterCriterias } from 'components/ui/Filters/ActiveFilterCriterias';
import { ActiveFilterCriteria } from 'components/ui/Filters/types';
import { getFilterCriteria } from 'components/ui/Filters/utils';
import { getEntityParametersIdTitleMap } from 'utils/entity';

import { ImportDetailsProps } from '../../ImportDetails/ImportDetails';

import { EntitiesTree } from './EntitiesTree';
import { LayersFooter } from './LayersFooter';
import { ActiveFilterCriteriasMap } from './types';
import {
  getEntitiesTree,
  getFilterCriteriaParam,
  getFilterCriteriaParams,
} from './utils';

interface SidebarContentProps {
  isPublic: boolean;
  setLayerDetailsProps: Dispatch<SetStateAction<LayerDetailsProps | undefined>>;
  setAccessControlDetailsProps: Dispatch<
    SetStateAction<AccessControlDetailsProps | undefined>
  >;
  setImportDetailsProps: Dispatch<
    SetStateAction<ImportDetailsProps | undefined>
  >;
  search: string;
  setSearch: (input: string) => void;
  activeFilterCriteriasMap: ActiveFilterCriteriasMap;
  setActiveFilterCriteriasMap: Dispatch<
    SetStateAction<ActiveFilterCriteriasMap>
  >;
}

export const getFilterableParameters = (
  parameters: EntityParameter[],
  filterableParametersMap: Record<number, string>
): EntityParameter[] =>
  parameters.filter((parameter) => filterableParametersMap[parameter.id]);

const SidebarContent: FC<SidebarContentProps> = ({
  isPublic,
  setLayerDetailsProps,
  setAccessControlDetailsProps,
  setImportDetailsProps,
  search,
  setSearch,
  activeFilterCriteriasMap,
  setActiveFilterCriteriasMap,
}) => {
  const entitiesMap = useAppSelector(entitiesMapSelector);
  const entityCountersMap = useAppSelector(entityCountersMapSelector);
  const hierarchy = useAppSelector(hierarchySelector);
  const isLoading = useAppSelector((state) =>
    loaderSelector(state, MAP_ENTITIES_LOADER)
  );
  const dispatch = useAppDispatch();

  const objectTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_OBJECT)
  );
  const objectParameters = objectTemplate?.parameters || [];
  const entityFilterableParametersMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        objectParameters,
        MAP_ENTITY_FILTERABLE_PARAMS
      ),
    [objectParameters]
  );

  const entityFilterableParameters = useMemo(
    () =>
      getFilterableParameters(objectParameters, entityFilterableParametersMap),
    [objectParameters]
  );

  const filterCriterias = useMemo(
    () =>
      entityFilterableParameters.map((parameter) =>
        getFilterCriteria(parameter)
      ),
    [entityFilterableParameters]
  );

  const handleFilter = (value: string, filterCriterias: FilterCriteria[]) => {
    const filterCriteriasParam = !!filterCriterias.length && {
      filterCriteria: filterCriterias,
    };

    const isWholeNumber = (s: string) =>
      s.replace(new RegExp('^0+(?!$)'), '') === String(parseInt(s, 10));

    const queryOrId = isWholeNumber(value)
      ? {
          idQuery: String(parseInt(value, 10)),
        }
      : {
          query: value,
        };

    dispatch(
      getMapFilteredEntitiesThunk({
        ...queryOrId,
        ...filterCriteriasParam,
      })
    );
  };

  const objectParametersIdTitleMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        objectTemplate?.parameters ?? [],
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      ),
    [objectTemplate]
  );

  const activeFilterCriteriasList = useMemo(
    () => getFilterCriteriaParams(activeFilterCriteriasMap),
    [activeFilterCriteriasMap]
  );

  const entitiesTreeData = useMemo(
    () => getEntitiesTree(entitiesMap, entityCountersMap, hierarchy),
    [entitiesMap, entityCountersMap, hierarchy]
  );

  const treeData = entitiesTreeData.filter(
    (el) => el.entity.isPersonalSpace !== isPublic
  );

  const handleSearch = useDebouncedCallback(
    (value: string, filterCriterias: FilterCriteria[]) =>
      handleFilter(value, filterCriterias),
    700
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
    handleSearch(value, activeFilterCriteriasList);
  };

  const handleFilterCriteriaApply = (criteria: ActiveFilterCriteria) => {
    setActiveFilterCriteriasMap((state) => ({
      ...state,
      [criteria.id]: criteria,
    }));
    handleFilter(search, [
      ...activeFilterCriteriasList,
      getFilterCriteriaParam(criteria),
    ]);
  };

  const handleFilterCriteriaRemove = (criteria: ActiveFilterCriteria) => {
    const { [criteria.id]: deselected, ...restCriterias } =
      activeFilterCriteriasMap;
    setActiveFilterCriteriasMap(restCriterias);
    handleFilter(search, getFilterCriteriaParams(restCriterias));
  };

  const handleAddProject = () =>
    setLayerDetailsProps({ onClose: () => setLayerDetailsProps(undefined) });

  const handleImportKML = () =>
    setImportDetailsProps({
      parentEntityID: 0,
      onClose: () => setImportDetailsProps(undefined),
    });

  return (
    <div className="relative flex flex-col items-start w-full h-full pt-4 rounded-[inherit]">
      <TextInput
        value={search}
        placeholder="Начните поиск"
        searchIcon
        className="w-full px-4"
        onChange={handleSearchChange}
      >
        <FilterDropdown
          criterias={filterCriterias}
          onCriteriaApply={handleFilterCriteriaApply}
        />
      </TextInput>
      <ActiveFilterCriterias
        activeCriterias={Object.values(activeFilterCriteriasMap)}
        onCriteriaRemove={handleFilterCriteriaRemove}
      />
      {isLoading && (
        <Loader className="absolute h-full top-0 z-10 bg-dark rounded-[inherit]" />
      )}
      <span className="px-9 py-1 tpg-b1">
        {isPublic ? 'База знаний' : 'Личное пространство'}
      </span>
      <EntitiesTree
        data={treeData}
        objectTemplate={objectTemplate}
        entitiesMap={entitiesMap}
        entityCountersMap={entityCountersMap}
        objectParametersMap={objectParametersIdTitleMap}
        search={search}
        onChangeLayerDetailsProps={setLayerDetailsProps}
        onChangeAccessControlDetailsProps={setAccessControlDetailsProps}
        onChangeImportDetailsProps={setImportDetailsProps}
      />
      <LayersFooter
        onAddProject={handleAddProject}
        onImportKML={handleImportKML}
      />
    </div>
  );
};

export default SidebarContent;
