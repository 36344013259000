import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getAllEntityTemplateParameters,
  searchEntities,
  upsertEntity,
} from 'api/entities';
import cn from 'classnames';
import { errorMessages, successMessages } from 'constants/errors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FilterCriteria, IEntitySearchRequest } from 'interfaces/entity';
import { mapActions } from 'store';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { Entity, EntityParameter } from 'types/entities';

import EntitiesSidebar from 'components/Entities/EntitiesSidebar';
import { Header } from 'components/Header/Header';
import { Switch, TextInput } from 'components/ui';
import AstraTable, {
  AstraTableFetchParams,
  getTablePagingInitialParamsBasedOnUrl,
} from 'components/ui/Table/AstraTable';
import { AstraTableHeadCell } from 'components/ui/Table/AstraTableHeader';
import { notify } from 'utils';

import {
  constructTableHeaderWithTitleByParams,
  renderEntityTableRow,
} from './utils';

const EntitiesScreen = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const mapObjectTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, 'mapObject')
    ) || 0;

  const initialTableParamsFromUrl = getTablePagingInitialParamsBasedOnUrl(
    searchParams,
    ''
  );
  const [searchQuery, setSearchQuery] = useState(
    initialTableParamsFromUrl.query
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSidebarHidden, setSidebarHidden] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<FilterCriteria[]>([]);

  const [entitiesHeadCells, setEntitiesHeadCells] = useState<
    AstraTableHeadCell<any>[]
  >([]);
  // TODO update after redux rework
  const [relatedParameters, setRelatedParameters] = useState<EntityParameter[]>(
    []
  );

  useEffect(() => {
    const fetchTemplateParameters = async () => {
      const templateParameters = await getAllEntityTemplateParameters(
        mapObjectTemplateId
      );
      setRelatedParameters(templateParameters);
      const cells = constructTableHeaderWithTitleByParams(templateParameters);
      setEntitiesHeadCells(cells);
    };
    fetchTemplateParameters();
  }, []);

  const onFilterAdd = (parameterId: number, value: string) => {
    setSelectedFilters((prevState) => {
      const filterIndex = prevState.findIndex(
        (filter) => filter.parameterID === parameterId
      );

      if (filterIndex > -1) {
        const updatedFilters = [...prevState];
        updatedFilters[filterIndex] = {
          ...updatedFilters[filterIndex],
          values: [...updatedFilters[filterIndex].values, value],
        };
        return updatedFilters;
      }

      return [...prevState, { parameterID: parameterId, values: [value] }];
    });
  };

  const onFilterRemove = (parameterId: number, value: string) => {
    setSelectedFilters((prevState) => {
      const filterIndex = prevState.findIndex(
        (filter) => filter.parameterID === parameterId
      );

      if (filterIndex > -1) {
        const updatedFilters = [...prevState];
        const updatedValues = updatedFilters[filterIndex].values.filter(
          (val) => val !== value
        );

        if (updatedValues.length > 0) {
          updatedFilters[filterIndex] = {
            ...updatedFilters[filterIndex],
            values: updatedValues,
          };
        } else {
          updatedFilters.splice(filterIndex, 1);
        }

        return updatedFilters;
      }

      return prevState;
    });
  };

  const resetFilters = () => {
    setSelectedFilters([]);
  };

  const fetchRows = useMemo(
    () => async (params: AstraTableFetchParams<any>) => {
      const preparedParams: IEntitySearchRequest = params.sortBy
        ? {
            // todo search-V2 has no support of size/start rn
            // size: params.size,
            // start: params.start,
            query: params.searchQuery,
            sortingCriteria: {
              parameterID: Number(params.sortBy),
              direction: params.sortDirection,
            },
            filterCriteria: selectedFilters,
          }
        : {
            // size: params.size,
            // start: params.start,
            query: params.searchQuery,
            filterCriteria: selectedFilters,
          };

      const response = await searchEntities(preparedParams);

      return {
        rows: response.entities.map((el) => el.entity),
        total: response.entities.length,
      };
    },
    [selectedFilters]
  );

  const handleCreationModalOpen = () => {
    navigate(`/entities/new`);
  };

  const dispatch = useAppDispatch(); // TODO move to correct redux

  const handleRowClick = (row: Entity) => {
    if (isEditMode) {
      return;
    }

    dispatch(mapActions.setCurrentEntity(row));
    navigate(`/entities/${row.id}`);
  };

  const renderHeader = () => (
    <Header className="w-full">
      <TextInput
        value={searchQuery}
        placeholder="Поиск по таблице..."
        searchIcon
        isReset
        className="w-[370px] ml-11"
        onChange={setSearchQuery}
      />
      <div className="ml-auto flex flex-row gap-x-2 items-center">
        <span className="mr-2 cursor-pointer" onClick={handleCreationModalOpen}>
          + Создать объект
        </span>
        <span>Режим редактирования</span>
        <Switch
          checked={isEditMode}
          onChange={() => setIsEditMode(!isEditMode)}
        />
      </div>
    </Header>
  );

  const handleUpdateRow = (entity: Entity) => {
    upsertEntity(entity)
      .then(() => notify.success(successMessages.ENTITY_UPDATE_SUCCESS))
      .catch(() => notify.error(errorMessages.ENTITY_UPDATE_ERROR));
    return entity;
  };

  const renderTable = () => (
    <div onClick={(e) => e.stopPropagation()}>
      <AstraTable
        headCells={entitiesHeadCells}
        rowIdExtractor={(row) => row.id}
        isEditMode={isEditMode}
        searchQuery={searchQuery}
        fetchRows={fetchRows}
        handleRowClick={handleRowClick}
        // TODO add report generation here
        handleSelectedRows={(rows) => rows}
        handleRowUpdate={handleUpdateRow}
        renderRowCells={(row, props) =>
          renderEntityTableRow(row, props, relatedParameters)
        }
        selectedFilters={selectedFilters}
        onFilterAdd={onFilterAdd}
        onFilterRemove={onFilterRemove}
        resetFilters={resetFilters}
        classNames={{
          root: cn(`!h-[calc(100vh-148px)]`),

          row: !isEditMode ? 'group hover:!bg-ultrablack' : undefined,
          cell: !isEditMode ? 'group-hover:!bg-[inherit]' : undefined,
        }}
      />
    </div>
  );

  return (
    <div>
      {renderHeader()}
      <div className="w-full">
        <EntitiesSidebar
          isSidebarHidden={isSidebarHidden}
          setSidebarHidden={setSidebarHidden}
        />

        <div
          className={cn(
            `w-[calc(100%-474px)] p-6 ml-[474px] transition-[width,margin] duration-300 ease-in-out`,
            isSidebarHidden && `w-[calc(100%-54px)] ml-[54px]`
          )}
        >
          {renderTable()}
        </div>
      </div>
    </div>
  );
};

export default EntitiesScreen;
