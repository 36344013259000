import React from 'react';
import MarkdownEditor from '@uiw/react-markdown-editor';

import { MARKDOWN_COMMANDS } from '../../../constants/reports';
import { PersonReportCustomTextBlockValue } from '../../../interfaces/reports';
import { TextInput } from '../../ui';
import { MultiLineEditor } from '../../ui/MultiLineEditor';

import './style.scss';

interface PersonReportCardCustomTextProps {
  values: PersonReportCustomTextBlockValue[];
  onChange: (v: PersonReportCustomTextBlockValue[]) => void;
  isFullScreen?: boolean;
}

export function PersonReportCardCustomText({
  values,
  onChange,
  isFullScreen,
}: PersonReportCardCustomTextProps) {
  return (
    <MultiLineEditor<PersonReportCustomTextBlockValue>
      title={''}
      values={values}
      renderInput={(currentValue, onInputValueChange) => (
        <div className="report-card-custom-text__inputs">
          <TextInput
            className={'profile-card__input-block_text-input'}
            labelText={'Имя блока'}
            value={currentValue.title}
            onChange={(v) => onInputValueChange({ ...currentValue, title: v })}
          />
          <div className="editor">
            <MarkdownEditor
              className="profile-card__markdown-editor"
              value={currentValue.text}
              height="250px"
              onChange={(v) => onInputValueChange({ ...currentValue, text: v })}
              toolbars={MARKDOWN_COMMANDS}
              theme="light"
            />
          </div>
        </div>
      )}
      setter={onChange}
      emptyValue={{
        text: '',
        title: '',
      }}
      isFullScreen={isFullScreen}
    />
  );
}
