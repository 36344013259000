import { FC, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import { ReactComponent as ChevronIcon } from 'images/newIcons/down.svg';

import { EditableName } from './EditableName';
import { Name } from './Name';
import { TArrowClickEvent, TRenderEditingName } from './types';

interface IFolderProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  editable?: boolean;
  editing?: boolean;
  selected?: boolean;
  open: boolean;
  empty?: boolean;
  renderArrow?: ReactNode;
  renderIcon?: ReactNode;
  renderName?: ReactNode;
  renderEditingName?: TRenderEditingName;
  renderPostfix?: ReactNode;
  renderCounter?: ReactNode;
  onEdit?: (value: string) => void;
  onOpen: (open: boolean) => void;
  onEditingChange?: (editing: boolean) => void;
  onOpenChange: (editing: boolean) => void;
}

export const Folder: FC<IFolderProps> = ({
  name,
  editable,
  editing,
  selected,
  open,
  empty,
  renderArrow,
  renderIcon,
  renderName,
  renderEditingName,
  renderPostfix,
  renderCounter,
  onEdit,
  onOpen,
  onEditingChange,
  onOpenChange,
  ...props
}) => {
  const handleArrowClick = (e: TArrowClickEvent) => {
    e.stopPropagation();
    onOpenChange(open);
    onOpen(open);
  };

  return (
    <div
      className={cn(
        'group w-full flex justify-between items-center gap-2 overflow-hidden tpg-c1',
        empty && 'empty',
        selected && 'selected'
      )}
      {...props}
    >
      <div className="w-full flex items-center gap-1 overflow-hidden cursor-pointer">
        <div
          className={cn(
            'w-4 h-4 shrink-0 leading-[0] [&>svg>path]:fill-tpg_title group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product',
            !open && 'rotate-[-90deg]',
            empty && 'invisible'
          )}
          onClick={handleArrowClick}
        >
          {renderArrow || <ChevronIcon />}
        </div>
        {renderIcon && (
          <div className="w-[20px] h-[20px] shrink-0 [&>svg>path]:fill-tpg_title group-[.empty:not(.selected)]:[&>svg>path]:fill-tpg_light group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
            {renderIcon}
          </div>
        )}
        <EditableName
          value={name}
          editable={editable}
          editing={editing}
          className="overflow-hidden"
          renderEditingName={renderEditingName}
          onEditingChange={onEditingChange}
          onEdit={onEdit}
        >
          <Name
            value={name}
            postfix={renderPostfix}
            className="text-tpg_title group-[.selected]:text-main_product group-hover:!text-bright_product"
          >
            {renderName}
          </Name>
        </EditableName>
      </div>
      {renderCounter && (
        <span className="shrink-0 text-tpg_light group-[.selected]:text-main_product group-hover:!text-dark_product">
          {renderCounter}
        </span>
      )}
    </div>
  );
};
