import { useCallback, useState } from 'react';
import { PASSWORD_LIMIT, USER_NAME_LIMIT } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ILoginValues } from 'interfaces';
import { loginThunk } from 'store/slices/auth/actions';
import { authSelector } from 'store/slices/auth/selectors';

import { Button, TextInput } from 'components/ui';
import { validate } from 'utils/validations/loginForm';

import './style.scss';

const Login = () => {
  const dispatch = useAppDispatch();
  const { pending, error } = useAppSelector(authSelector);
  const [errors, setErrors] = useState<ILoginValues>({} as ILoginValues);
  const [values, setValues] = useState<ILoginValues>({
    username: '',
    password: '',
  });

  const handleLogin = async () => {
    const errors = validate(values);
    setErrors(errors);

    if (errors.username || errors.password) return;

    dispatch(loginThunk(values));
  };

  const handleChange = useCallback(
    (field: keyof ILoginValues) => (value: string) => {
      if (errors[field]) {
        errors[field] = '';
      }

      setValues((values) => ({ ...values, [field]: value }));
    },
    [errors]
  );

  return (
    <div className="login">
      <div className="login_container">
        <h1 className="tpg-title-five login__title">Авторизация</h1>
        <form className="login__form" onSubmit={(e) => e.preventDefault()}>
          <TextInput
            value={values.username}
            maxLength={USER_NAME_LIMIT}
            autoComplete="username"
            labelText="Логин"
            placeholder="Введите логин"
            error={error || errors.username}
            onChange={handleChange('username')}
            className="w-full"
          />
          <TextInput
            isPassword
            value={values.password}
            maxLength={PASSWORD_LIMIT}
            autoComplete="current-password"
            className="mt-3 w-full"
            labelText="Пароль"
            placeholder="Введите пароль"
            error={error || errors.password}
            onChange={handleChange('password')}
          />
          {/* <Link
            className="tpg-text-three login_password"
            to={authRoutes.FORGOT_PASSWORD}
          >
            Забыли пароль?
          </Link> */}
          <Button
            className="w-full mt-8"
            isLoading={pending}
            disabled={!values.username || !values.password}
            title="Войти"
            type="submit"
            onClick={handleLogin}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
