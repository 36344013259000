import { constants as MapboxDrawConstants } from '@mapbox/mapbox-gl-draw';
import { ReactComponent as LayersActive } from 'images/newIcons/layersActive.svg';
import { ReactComponent as LayersPassive } from 'images/newIcons/layersPassive.svg';
import { ReactComponent as LogisticActive } from 'images/newIcons/logisticActive.svg';
import { ReactComponent as LogisticPassive } from 'images/newIcons/logisticPassive.svg';
import { ReactComponent as ObjectsActive } from 'images/newIcons/objectsActive.svg';
import { ReactComponent as ObjectsPassive } from 'images/newIcons/objectsPassive.svg';
import { ReactComponent as Oper } from 'images/newIcons/oper.svg';
import { ReactComponent as Satellite } from 'images/newIcons/satellite.svg';
import CircleBlue from 'images/raster/circleBlue.png';
import CircleBlueHighlighted from 'images/raster/circleBlueHighlighted.png';
import PinBlue from 'images/raster/pinBlue.png';
import PinBlueHighlighted from 'images/raster/pinBlueHighlighted.png';
import Radius from 'images/raster/radius.png';
import RadiusHighlighted from 'images/raster/radiusHighlighted.png';
import { IMapTypes, ISelectOption, TCircle } from 'interfaces';

import { GEOSPOOF_COLOR } from './geospoof';
import { mapRoutes } from './routes';

export const MapboxDrawEvents = MapboxDrawConstants.events;
export const MapboxDrawCustomEvents = {
  MOUSE_MOVE: 'draw.mouse_move',
  TAP: 'draw.tap',
};

export const MAP_BOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';

export const MAP_LAYER_NAME_LIMIT = 256;

export const MAP_MIN_ZOOM_LEVEL = 0;
export const MAP_MAX_ZOOM_LEVEL = 22;

/**
 * Sidebar sizes
 */
export const SIDEBAR_INITIAL_CONTAINER_WIDTH = 78;

export const SIDEBAR_CONTENT_WIDTH = 378;

/**
 * Restrictions on the number of characters that can be entered in the map search field
 */
export const SEARCH_VALUE_MAX_LIMIT = 1000;

/**
 * Limit of the coordinate range
 */
export const LATITUDE_MIN = -90;
export const LATITUDE_MAX = 90;
export const LONGITUDE_MIN = -180;
export const LONGITUDE_MAX = 180;

export const DEFAULT_DATE_FORMAT = 'DD.MM.YY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_RU = 'DD.MM.YYYY';

export const mapObjectType: ISelectOption[] = [
  { label: 'Склад ВиВТ', value: 'militarystorage' },
  { label: 'Склад БК', value: 'ammunition' },
  { label: 'Склад ГСМ', value: 'lubricants' },
  { label: 'Стоянка техники', value: 'stand' },
  { label: 'Стоянка АТ', value: 'aviation' },
  { label: 'Расположение л/c', value: 'location' },
  { label: 'Позиция военной техники', value: 'militaryequipment' },
  { label: 'Позиция артиллерии', value: 'artillery' },
  { label: 'Позиция РЛС', value: 'radio' },
  { label: 'Позиция ПВО', value: 'antiair' },
  { label: 'Командный пункт', value: 'headquarters' },
  { label: 'Ж/Д-инфраструктура', value: 'railway' },
  { label: 'Объект ВПК', value: 'military' },
  { label: 'Пехотный окоп / фортификация', value: 'trench' },
  { label: 'Энергетическая инфраструктура', value: 'energy' },
  { label: 'Персоналии', value: 'personalities' },
  { label: 'Другое', value: 'other' },
];

export const opacityOptions: ISelectOption[] = [
  { label: '100%', value: 100 },
  { label: '75%', value: 75 },
  { label: '50%', value: 50 },
  { label: '25%', value: 25 },
  { label: '0%', value: 0 },
];

export const FEATURE_COLORS = [
  '#d32f2f',
  '#F44870',
  '#f58de4',
  '#7a1fa0',
  '#512da8',
  '#303f9f',
  '#2196f3',
  '#00bcd4',
  '#00796b',
  '#388e3c',
  '#dce775',
  '#f5eb8d',
  '#fbc02d',
  '#f57c00',
  '#ff5722',
  '#a0877e',
  '#5d4037',
  '#455a64',
  '#333333',
  '#ffffff',
];

export enum FeatureTypes {
  POINT = 'Point',
  LINE = 'LineString',
  POLYGON = 'Polygon',
}

export enum MapObjectTypes {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
}

export type TTabTitle =
  | 'search'
  | 'layers'
  | 'types'
  | 'imagery'
  | 'operational'
  | 'logistic'
  | 'features';

export interface ISidebarTab {
  link: string;
  passiveIcon: JSX.Element;
  activeIcon: JSX.Element;
  title: TTabTitle;
}

export const mapSidebarTabs: ISidebarTab[] = [
  // {
  //   link: mapRoutes.SEARCH,
  //   passiveIcon: <Heatmap />,
  //   activeIcon: <Heatmap />,
  //   title: 'search',
  // },
  {
    link: mapRoutes.LAYERS,
    passiveIcon: <ObjectsPassive />,
    activeIcon: <ObjectsActive />,
    title: 'layers',
  },
  {
    link: mapRoutes.TYPES,
    passiveIcon: <LayersPassive />,
    activeIcon: <LayersActive />,
    title: 'types',
  },
  {
    link: mapRoutes.OPERATIONAL,
    passiveIcon: <Oper />,
    activeIcon: <Oper />,
    title: 'operational',
  },
  {
    link: mapRoutes.IMAGERY,
    passiveIcon: <Satellite />,
    activeIcon: <Satellite />,
    title: 'imagery',
  },
  {
    link: mapRoutes.LOGISTIC,
    passiveIcon: <LogisticPassive />,
    activeIcon: <LogisticActive />,
    title: 'logistic',
  },
];

export const mapLocale = {
  'AttributionControl.ToggleAttribution': 'Toggle attribution',
  'AttributionControl.MapFeedback': 'Map feedback',
  'FullscreenControl.Enter': 'Enter fullscreen',
  'FullscreenControl.Exit': 'Exit fullscreen',
  'GeolocateControl.FindMyLocation': 'Find my location',
  'GeolocateControl.LocationNotAvailable': 'Location not available',
  'LogoControl.Title': 'Mapbox logo',
  'Map.Title': 'Map',
  'NavigationControl.ResetBearing': 'Reset bearing to north',
  'NavigationControl.ZoomIn': 'Zoom in',
  'NavigationControl.ZoomOut': 'Zoom out',
  'ScrollZoomBlocker.CtrlMessage': 'Use ctrl + scroll to zoom the map',
  'ScrollZoomBlocker.CmdMessage': 'Use ⌘ + scroll to zoom the map',
  'TouchPanBlocker.Message': 'Use two fingers to move the map',
};

export const initialFeatureFiltersState = {
  polygons: true,
  lines: true,
  markers: true,
  all: true,
  global: {
    type: '',
    object: '',
    date: null,
    createdAt: null,
    status: '',
    report: null,
    client: null,
  },
};

export const initialMapTypesState: IMapTypes = {
  // подложки
  googleSatellite: {
    active: true,
    type: 'style',
  },
  mapboxMinimalism: {
    active: false,
    type: 'style',
  },
  streetMap: {
    active: false,
    type: 'style',
  },
  mapboxSatellite: {
    active: false,
    type: 'style',
  },
  // верхние слои
  infrastructure: {
    active: false,
    type: 'layer',
  },
  elevation: {
    active: false,
    type: 'layer',
  },
  fire: {
    active: false,
    type: 'layer',
    data: new Date().toISOString().split('T')[0],
  },
  railroads: {
    active: false,
    type: 'layer',
  },
  railStations: {
    active: false,
    type: 'layer',
  },
  demMap: {
    active: false,
    type: 'layer',
  },
  gcFrontline: {
    active: false,
    type: 'layer',
  },
  gcPlacemarks: {
    active: false,
    type: 'layer',
  },
  googleSatelliteUpper: {
    active: false,
    type: 'layer',
  },
  mapboxSatelliteUpper: {
    active: false,
    type: 'layer',
  },
};

export const initialMapAttributesState = {
  reports: [],
  clients: [],
};

export enum imageUploadTypes {
  TIF = 'tif',
  PNG = 'png',
  BATCH = 'batch',
}

export const imageTypeOptions: ISelectOption[] = [
  { label: '.tif', value: imageUploadTypes.TIF },
  { label: '.png + метаданные', value: imageUploadTypes.PNG },
  { label: 'множественная загрузка', value: imageUploadTypes.BATCH },
];

export const converterDirectionOptions: ISelectOption[] = [
  { label: 'WGS в СК-42', value: 'wgsTosk42' },
  { label: 'WGS в УСК2000', value: 'wgsTousk2000' },
  { label: 'WGS в MGRS', value: 'wgsTomgrs' },
  { label: 'СК-42 в WGS', value: 'sk42Towgs' },
  { label: 'СК-42 в УСК2000', value: 'sk42Tousk2000' },
  { label: 'СК-42 в MGRS', value: 'sk42Tomgrs' },
  { label: 'УСК2000 в WGS', value: 'usk2000Towgs' },
  { label: 'УСК2000 в СК-42', value: 'usk2000Tosk42' },
  { label: 'УСК2000 в MGRS', value: 'usk2000Tomgrs' },
  { label: 'MGRS в WGS', value: 'mgrsTowgs' },
  { label: 'MGRS в СК-42', value: 'mgrsTosk42' },
  { label: 'MGRS в УСК2000', value: 'mgrsTousk2000' },
];

export const DEFAULT_HEIGHT = 180;

export const relevanceTypes: ISelectOption[] = [
  { label: 'Другое значение', value: 'other' },
  { label: '1 день', value: '1day' },
  { label: '3 дня', value: '3days' },
  { label: '1 неделя', value: '1week' },
  { label: '1 месяц', value: '1month' },
  { label: '3 месяца', value: '3months' },
  { label: '6 месяцев', value: '6months' },
  { label: '1 год', value: '1year' },
  { label: 'с 24.02.2022', value: 'fromStart' },
];

export const circleOptions: TCircle = {
  steps: 64,
  units: 'meters',
  properties: {
    fillColor: GEOSPOOF_COLOR.activeFill,
    lineColor: GEOSPOOF_COLOR.activeLine,
    width: 2,
    opacity: 0.4,
  },
};

export const CIRCLE_MARKER_IMAGE = 'circle-marker-image';
export const CIRCLE_HIGHLIGHTED_MARKER_IMAGE =
  'circle-highlighted-marker-image';
export const PIN_HIGHLIGHTED_MARKER_IMAGE = 'pin-highlighted-marker-image';
export const PIN_MARKER_IMAGE = 'pin-marker-image';
export const RADIUS_MARKER_IMAGE = 'radius-marker-image';
export const RADIUS_HIGHLIGHTED_MARKER_IMAGE =
  'radius-highlighted-marker-image';

export const CUSTOM_MAP_IMAGES = [
  { name: CIRCLE_MARKER_IMAGE, image: CircleBlue },
  { name: CIRCLE_HIGHLIGHTED_MARKER_IMAGE, image: CircleBlueHighlighted },
  { name: PIN_MARKER_IMAGE, image: PinBlue },
  { name: PIN_HIGHLIGHTED_MARKER_IMAGE, image: PinBlueHighlighted },
  { name: RADIUS_MARKER_IMAGE, image: Radius },
  { name: RADIUS_HIGHLIGHTED_MARKER_IMAGE, image: RadiusHighlighted },
];

export const MAPBOX = 'mapbox';
export const MAPBOX_MAP_CONTAINER = 'mapbox-container';

export const BOUNDARY_GRID_SOURCE = 'boundary-grid-source';
export const CIRCLE_SOURCE = 'circle-source';
export const POINT_SOURCE = 'point-source';
export const LINE_SOURCE = 'line-source';
export const POLYGON_SOURCE = 'polygon-source';
export const LABEL_SOURCE = 'label-source';

export const BOUNDARY_GRID_CELL_LAYER = 'boundary-grid-cell-layer';
export const IMAGERY_PREVIEW_FILL_LAYER = 'imagery-preview-fill-layer';
export const IMAGERY_BOUNDARY_LAYER = 'imagery-boundary-layer';

export const IMAGERY_SOURCE_PREFIX = 'imagery-source';

export const LAYERS_POINT = 'layers-point';
export const LAYERS_POINT_MAIN = 'layers-point-main';

export const LAYERS_LINE = 'layers-line';
export const LAYERS_LINE_MAIN_FILL = 'layers-line-main-fill';

export const LAYERS_POLYGON = 'layers-polygon';
export const LAYERS_POLYGON_MAIN_FILL = 'layers-polygon-main-fill';
export const LAYERS_POLYGON_MAIN_LINE = 'layers-polygon-main-line';

export const DRAW_INACTIVE_COLD_LAYER_ID = 'gl-draw-polygon-fill-inactive.cold';
export const DRAW_INACTIVE_HOT_LAYER_ID = 'gl-draw-polygon-fill-inactive.hot';
export const DRAW_ACTIVE_COLD_LAYER_ID = 'gl-draw-polygon-fill-active.cold';
export const DRAW_ACTIVE_HOT_LAYER_ID = 'gl-draw-polygon-fill-active.hot';

export const DEFAULT_FLY_TO_SETTINGS = {
  zoom: 10,
  essential: true,
  speed: 6,
};

export const OFFSET_TO_FIT_PREVIEW = 0.001;
