import { useControl } from 'react-map-gl';
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { convertCoordinates } from 'api/converter';
import { MAP_BOX_TOKEN } from 'constants/map';
import { FeatureCollection } from 'geojson';
import { useAppDispatch } from 'hooks';
import { BLHCoordinates, CoordinateSystem } from 'interfaces';
import mapboxgl from 'mapbox-gl';
import { mapActions } from 'store';

import { getCoordinatesFromDecimalString } from 'utils';
import { getCoordinatesFromString } from 'utils/converterUtils';
import { isNumber } from 'utils/validations/number';

const DEFAULT_SK42_HEIGHT = 162;

type CoordinateResult = Result & {
  properties: {
    coordinateSystem?: CoordinateSystem;
    coordinates?: number[];
  };
};

interface GeocoderResultResponse {
  result: Result | CoordinateResult;
}

type CoordsProcessorT = (
  coords: [number, number],
  query: string
) => Promise<Result[]>;

const getOrderedCoordinatesFromString = (value: string) =>
  getCoordinatesFromString(
    String(value)
      .replaceAll(/"|(``)/g, '″')
      .replaceAll(/'|`/g, '′') ?? ''
  )?.reverse();

const prepareCoordinateResult = (
  lat: number,
  lon: number,
  query: string,
  placeName: string,
  coordinateSystem?: CoordinateSystem
): CoordinateResult => ({
  address: '',
  bbox: [lon, lat, lon, lat],
  center: [lon, lat],
  context: [],
  geometry: {
    type: 'Point',
    coordinates: [lon, lat],
  },
  place_name: placeName,
  place_type: ['place'],
  properties: {
    coordinateSystem,
    coordinates: getOrderedCoordinatesFromString(query),
  },
  relevance: 0,
  text: query,
  type: 'Feature',
});

const maybeGetCoords = async (
  query: string,
  coordsProcessorFunc: CoordsProcessorT
) => {
  const maybeCoords = getCoordinatesFromDecimalString(query);

  if (maybeCoords) {
    const [lat, lon] = maybeCoords;

    if (isNumber(lat) && isNumber(lon)) {
      return await coordsProcessorFunc([lat, lon], query);
    }
  }

  return [];
};

const prepareWgsResult: CoordsProcessorT = async (coords, query) => [
  prepareCoordinateResult(
    coords[0],
    coords[1],
    query,
    `Перейти к WGS координате`,
    'wgs'
  ),
];

const preparePossibleSk42Query = (query: string): string =>
  query.replace('X:', '').replace('Y:', '');

const prepareSk42Result: CoordsProcessorT = async (coords, query) => {
  const resultWgs = await convertCoordinates('sk42', 'wgs', {
    x: coords[0],
    y: coords[1],
    h: DEFAULT_SK42_HEIGHT,
  }).catch(() => null);

  if (resultWgs && resultWgs.to === 'wgs') {
    return [
      prepareCoordinateResult(
        resultWgs.payload.b,
        resultWgs.payload.l,
        query,
        `Перейти к СК-42 координате`,
        'sk42'
      ),
    ];
  }

  return [];
};

const asyncLocalGeocoder = async (query: string) => {
  if (query.includes('X')) {
    const result = await maybeGetCoords(
      preparePossibleSk42Query(query),
      prepareSk42Result
    );

    // dirty trick, however it fails if this function returns actual FeatureCollection
    return result as unknown as FeatureCollection;
  }

  const result = await maybeGetCoords(query, prepareWgsResult);

  return result as unknown as FeatureCollection;
};

const getGeocoderResult = async (
  e: GeocoderResultResponse
): Promise<number[]> => {
  if (!e.result?.properties?.coordinates) {
    return e.result.center;
  }

  if (e.result.properties.coordinateSystem === 'sk42') {
    const resultCoordinates = e.result.properties.coordinates.toReversed();
    const result = await convertCoordinates('sk42', 'wgs', {
      x: resultCoordinates[0],
      y: resultCoordinates[1],
      h: DEFAULT_SK42_HEIGHT,
    }).catch(() => null);

    const wgsCoordinates = (result?.payload ?? {
      b: 0,
      l: 0,
      h: 0,
    }) as BLHCoordinates;

    return [wgsCoordinates.l, wgsCoordinates.b];
  }

  return e.result.properties.coordinates;
};

export const useGeocoder = () => {
  const dispatch = useAppDispatch();

  useControl(
    () => {
      const geocoder = new MapboxGeocoder({
        accessToken: MAP_BOX_TOKEN,
        marker: false,
        mapboxgl,
        language: 'ru-RU',
        collapsed: false,
        placeholder: 'Начните поиск',
        flyTo: false,
        enableEventLogging: false,
        getItemValue: (item) => {
          if (item.properties?.coordinates) {
            setTimeout(() => geocoder.setInput(''), 200);

            return item.text;
          } else {
            return item.place_name;
          }
        },
        externalGeocoder: asyncLocalGeocoder,
      });
      geocoder.on('error', () => null);
      geocoder.on('result', async (e: GeocoderResultResponse) => {
        const geocoderResult = (await getGeocoderResult(e)) as [number, number];

        dispatch(mapActions.setGeocoderResult(geocoderResult));
        // is used to handle mapbox 422 response causing runtime error
        geocoder.clear();
      });

      return geocoder;
    },
    {
      position: 'bottom-left',
    }
  );
};
