import { ISelectOption } from 'interfaces';

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  patronymic?: string | null
) => {
  let fullName = lastName || '';

  if (firstName) {
    fullName += ` ${firstName}`;
  }

  if (patronymic) {
    fullName += ` ${patronymic}`;
  }

  return fullName.trim();
};

export function generateRandomString(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getOptionLabelByValue = (
  options: ISelectOption[],
  value: string | number | null | undefined
) =>
  options.find((option) => {
    if (typeof option.value === 'string' && typeof value === 'string') {
      return option.value.toLocaleLowerCase() === value.toLowerCase();
    }

    return option.value === value;
  })?.label;

export const generateCryptoSecurePassword = (
  length = 16,
  useUppercase = true,
  useNumbers = true,
  useSpecialChars = true
): string => {
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberChars = '0123456789';
  const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';

  const availableChars = [
    lowercaseChars,
    useUppercase && uppercaseChars,
    useNumbers && numberChars,
    useSpecialChars && specialChars,
  ]
    .filter(Boolean)
    .join('');

  const getRandomChar = (randomValue: number): string =>
    availableChars[randomValue % availableChars.length];

  const getRandomValues = (): Uint32Array => {
    const array = new Uint32Array(length);
    crypto.getRandomValues(array);
    return array;
  };

  return Array.from(getRandomValues()).map(getRandomChar).join('');
};
