import { notify } from 'utils';

import { TCoordsModeUnion } from '../types';

import { convertWgsDegreeToDecimal } from './converterUtils';

export const getCoordinatesFromDecimalString = (value: string) =>
  value ? value.split(',').map((item) => parseFloat(item)) : null;

export const getCoordinatesFromDegreeString = (value: string) =>
  value
    ? value
        .split(' ')
        .map((coordinate) => convertWgsDegreeToDecimal(coordinate))
    : null;

export const formatWgsCoords = (lat: number, lng: number) =>
  `${lat.toFixed(6)}, ${lng.toFixed(6)}`;

export const formatXYCoords = (x: number, y: number) => `X:${x}, Y:${y}`;

export const copyToClipboard = (
  text: string,
  message = 'Скопировано в буфер обмена'
) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notify.success(message);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getVisibleCoordMode = (coordsMode: TCoordsModeUnion) => {
  switch (coordsMode) {
    case 'wgs':
      return 'WGS';
    case 'sk42':
      return 'СК-42';
    case 'usk2000':
      return 'УСК2000';
  }
};
