import { DUMMY_ACCESS_RULES, ROOT_HIERARCHY_ID } from 'constants/entities';
import { MapEntity, MapEntityInfo, MapEntityState } from 'types';
import {
  AccessRules,
  Counter,
  Entity,
  EntityWithRelations,
} from 'types/entities';

import { mergeMaps } from 'utils';
import { getInitialLayerEntity } from 'utils/entity';

import { EntitiesMap, EntityCountersMap } from './types';

export const getMapEntity = (
  entity: EntityWithRelations,
  parentEntityId: number,
  layerTemplateId: number,
  objectTemplateId: number,
  storage: 'initial' | 'partial' | 'full'
): MapEntity => {
  const info = {
    type:
      (entity.entity.templateID === layerTemplateId && 'layer') ||
      (entity.entity.templateID === objectTemplateId && 'object'),
  } as MapEntityInfo;

  const predefinedStates: Record<string, MapEntityState> = {
    initial: { storage: 'none', active: false },
    partial: {
      storage: entity.entity.id === parentEntityId ? 'partial' : 'none',
      active: false,
    },
    full: { storage: 'full', active: true },
  };

  return {
    ...entity,
    info: info,
    state: predefinedStates[storage],
  };
};

export const getInitialMapEntity = (
  entity: Entity,
  layerTemplateId: number,
  objectTemplateId: number,
  accessRules: AccessRules,
  parentEntityID?: number
): MapEntity =>
  getMapEntity(
    {
      entity: entity,
      childIDs: [],
      parentIDs: parentEntityID ? [parentEntityID] : [0],
      ...accessRules,
    },
    ROOT_HIERARCHY_ID,
    layerTemplateId,
    objectTemplateId,
    'initial'
  );

export const getEmptyMapLayerEntity = (
  layerTemplateId: number,
  objectTemplateId: number
): MapEntity => {
  const layerEntity = getInitialMapEntity(
    getInitialLayerEntity(layerTemplateId),
    layerTemplateId,
    objectTemplateId,
    DUMMY_ACCESS_RULES
  );

  layerEntity.state.draft = true;

  return layerEntity;
};

export const getEntitiesMap = (
  entities: EntityWithRelations[],
  parentEntityId: number,
  storage: 'initial' | 'partial' | 'full',
  layerTemplateId: number,
  objectTemplateId: number,
  entitiesMap?: EntitiesMap,
  mergeEntityFunc?: (
    prevEntity: MapEntity | undefined,
    newEntity: MapEntity
  ) => MapEntity
): EntitiesMap =>
  entities.reduce((acc, curr) => {
    const entityId = curr.entity.id;
    const prevEntity = entitiesMap?.[entityId];
    const newEntity = getMapEntity(
      curr,
      parentEntityId,
      layerTemplateId,
      objectTemplateId,
      storage
    );

    const mergedEntity = mergeEntityFunc?.(prevEntity, newEntity);

    return {
      ...acc,
      [entityId]: mergedEntity ?? newEntity,
    };
  }, {});

export const getFilteredEntitiesMap = (
  stashedEntitiesMap: EntitiesMap,
  entitiesMap: EntitiesMap,
  newEntitiesMap: EntitiesMap,
  isFiltering: boolean
) => {
  const mergeEntities = (oldEntity: MapEntity, newEntity: MapEntity) => ({
    ...oldEntity,
    state: {
      storage: oldEntity.state.storage,
      active: newEntity.state.active,
    },
  });

  if (isFiltering) {
    const stashedMap = { ...stashedEntitiesMap, ...entitiesMap };

    const newMap = mergeMaps<MapEntity>(
      newEntitiesMap,
      stashedMap,
      false,
      mergeEntities
    );

    return { stashedEntitiesMap: stashedMap, entitiesMap: newMap };
  } else {
    const stashedMap = {};

    const newMap = mergeMaps<MapEntity>(
      stashedEntitiesMap,
      entitiesMap,
      true,
      mergeEntities
    );

    return { stashedEntitiesMap: stashedMap, entitiesMap: newMap };
  }
};

export const getEntityCountersMap = (counters: Counter[]): EntityCountersMap =>
  counters.reduce((acc, curr) => ({ ...acc, [curr.entityID]: curr.count }), {});

export const filterEntitiesMap = (
  entitiesMap: EntitiesMap,
  filterFunc: (mapEntity: MapEntity) => boolean
): EntitiesMap => {
  const passedEntries = Object.entries(entitiesMap).filter(([_, value]) =>
    filterFunc(value)
  );
  return Object.fromEntries<MapEntity>(passedEntries);
};

export const getAllChildrenID = (
  entitiesMap: EntitiesMap,
  mapEntity: MapEntity
): number[] =>
  mapEntity.childIDs.flatMap((id) => {
    if (entitiesMap[String(id)].info.type === 'layer')
      return getAllChildrenID(entitiesMap, entitiesMap[String(id)]);
    else return id;
  });
