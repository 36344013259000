import { DATE_FORMAT } from 'constants/map';
import {
  EntitiesMap,
  EntityCountersMap,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { TDateRange } from 'types';
import { Hierarchy } from 'types/entities';
import { MapEntity, MapEntityState } from 'types/map';

import { ActiveFilterCriteria } from 'components/ui/Filters/types';
import { getFormattedDate } from 'utils';

import {
  ActiveFilterCriteriasMap,
  EntityNodeData,
  EntityNodeDropData,
} from './types';

export const NODE_INDENT = 20;
export const LAYERS_UPDATE_DEBOUNCE = 300;

export const getFilterCriteriaParam = (criteria: ActiveFilterCriteria) => {
  const value =
    criteria.type === 'date'
      ? {
          gte: getFormattedDate(
            (criteria.value.value as TDateRange)[0],
            DATE_FORMAT,
            true
          ),
          lte: getFormattedDate(
            (criteria.value.value as TDateRange)[1],
            DATE_FORMAT,
            true
          ),
        }
      : String(criteria.value.value);

  return {
    parameterID: criteria.id,
    values: [value],
  };
};

export const getFilterCriteriaParams = (
  filterCriteriasMap: ActiveFilterCriteriasMap
) =>
  Object.values(filterCriteriasMap).map((criteria) =>
    getFilterCriteriaParam(criteria)
  );

export const getFolderEntityChildrenCount = (
  entitiesMap: EntitiesMap,
  id: string
) => {
  const entity = entitiesMap[id];

  return entity.childIDs.reduce((acc, curr): number => {
    const childEntity = entitiesMap[curr];

    if (childEntity?.info?.type === 'object') {
      return childEntity.state.active ? acc + 1 : acc;
    }

    if (childEntity?.info?.type === 'layer') {
      return acc + getFolderEntityChildrenCount(entitiesMap, String(curr));
    }

    return acc;
  }, 0);
};

export const getEntitiesTree = (
  entitiesMap: EntitiesMap,
  entityCountersMap: EntityCountersMap,
  hierarchy: Hierarchy,
  level = 0
): EntityNodeData[] =>
  hierarchy.children.reduce((acc, curr) => {
    const { id } = curr;
    const entity = entitiesMap[id];
    const parentId = entity?.parentIDs?.[0] ?? 0;
    const isFolder = entity?.info?.type === 'layer';
    const isObject = entity?.info?.type === 'object';

    if (isFolder) {
      const totalObjectCount = entityCountersMap[id];

      const selectedObjectsCount = getFolderEntityChildrenCount(
        entitiesMap,
        String(id)
      );

      const isSelected = !!(
        totalObjectCount && selectedObjectsCount === totalObjectCount
      );

      return [
        ...acc,
        {
          id: String(id),
          name: entity.entity.title,
          type: 'folder',
          isFolder: true,
          info: {
            level: level,
            parent: { id: String(parentId) },
            stats: {
              selectedObjectsCount: selectedObjectsCount,
              totalObjectsCount: totalObjectCount,
            },
          },
          state: {
            storage: entity.state.storage,
            selected: isSelected,
          },
          entity: entity,
          children: getEntitiesTree(
            entitiesMap,
            entityCountersMap,
            curr,
            level + 1
          ),
        },
      ];
    }

    if (isObject) {
      return [
        ...acc,
        {
          id: String(id),
          name: entity.entity.title,
          type: 'object',
          isFolder: false,
          info: {
            level: level,
            parent: { id: String(parentId) },
          },
          state: {
            storage: entity.state.storage,
            selected: entity.state.active,
          },
          entity: entity,
        },
      ];
    }

    return [...acc];
  }, [] as EntityNodeData[]);

export const getEntityChildrenWithUpdatedState = (
  entitiesMap: EntitiesMap,
  id: string,
  state: Partial<MapEntityState>,
  shouldUpdate: (entity: MapEntity) => boolean = () => true
) => {
  const selectedEntity = entitiesMap[id];

  if (!selectedEntity) {
    return {};
  }

  const updatedState = {
    ...selectedEntity.state,
    ...(shouldUpdate(selectedEntity) && state),
  };

  return {
    [selectedEntity.entity.id]: {
      ...selectedEntity,
      state: updatedState,
    },
    ...selectedEntity.childIDs.reduce(
      (acc, curr): EntitiesMap => ({
        ...acc,
        ...getEntityChildrenWithUpdatedState(
          entitiesMap,
          String(curr),
          state,
          shouldUpdate
        ),
      }),
      {}
    ),
  };
};

export const getNodeDropDisabled = (data: EntityNodeDropData) =>
  data.parentNode.isRoot && !data.dragNodes[0].data.isFolder;
