import { useEffect, useState } from 'react';
import cn from 'classnames';
import { ISelectOption } from 'interfaces';
import { useDebouncedCallback } from 'use-debounce';

import { Spinner } from 'components/ui/index';
import TextInput from 'components/ui/TextInput';
import { genericMemo, getFilteredOptions } from 'utils';

import {
  ElementPlacementUnion,
  ElementThemeUnion,
} from '../../../constants/routes';

import SelectItem from './SelectItem';

import './style.scss';

interface IOptionsListProps<T> {
  options: ISelectOption[];
  value: T;
  isLoading: boolean;
  withSearch: boolean;
  withEmpty: boolean;
  placeholder: string;
  handleClick: (value: T) => void;
  isCreating?: boolean;
  isAddMode: boolean;
  creationText?: string;
  onCreate?: (value: string) => void;
  theme?: ElementThemeUnion;
  isExpandable: boolean;
  menuPlacement?: ElementPlacementUnion;
  className?: string;
}

const OptionsList = <T extends string | number | null>({
  options,
  value,
  withSearch,
  withEmpty,
  isLoading,
  placeholder = 'Поиск',
  handleClick,
  isCreating,
  isAddMode,
  creationText,
  onCreate,
  isExpandable,
  theme = 'light',
  menuPlacement = 'bottom',
  className,
}: IOptionsListProps<T>) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterdOptions, setFilterdOptions] = useState(options);
  const [isOpenCreation, setOpenCreation] = useState(false);
  const [newOption, setNewOption] = useState('');

  useEffect(() => {
    document.getElementById(`option-${value}`)?.scrollIntoView({
      block: 'center',
    });
  }, []);

  useEffect(() => {
    if (withSearch && searchValue) {
      getOptions(searchValue);
    } else {
      setFilterdOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (withSearch) {
      getOptions(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!isCreating && newOption) {
      setNewOption('');
      setOpenCreation(false);
    }
  }, [isCreating]);

  const getOptions = useDebouncedCallback((search) => {
    let list = JSON.parse(JSON.stringify(options));

    if (search) {
      list = getFilteredOptions(list, search);
    }

    setFilterdOptions(list);
  }, 100);

  const handleEnter = () => {
    if (!isCreating && onCreate) {
      onCreate(newOption);
    }
  };

  const DEFAULT_TOP_HEIGHT = -215;
  const DEFAULT_BOTTOM_HEIGHT = 36;
  const OPTION_HEIGHT = 35;

  const getMenuHeight = () => {
    const getHeightByOptionsLength = (length: number) => {
      if (length === 0) return DEFAULT_TOP_HEIGHT + 142;
      return DEFAULT_TOP_HEIGHT + (5 - length) * OPTION_HEIGHT + 3; // magic number 3 is used to level out paddings
    };

    if (menuPlacement === 'bottom') return DEFAULT_BOTTOM_HEIGHT;

    if (options.length <= 5) return getHeightByOptionsLength(options.length);

    if (filterdOptions.length <= 5)
      return getHeightByOptionsLength(filterdOptions.length);

    return DEFAULT_TOP_HEIGHT;
  };

  const renderSearchInput = () => (
    <li
      className={cn(
        'select__options__search [&_svg]:w-5 [&_svg]:h-5 border-tpg_light border-solid',
        menuPlacement === 'top' ? 'border-t' : 'border-b'
      )}
      key="select-option-search"
    >
      <TextInput
        searchIcon
        isReset
        placeholder={placeholder || 'Введите название'}
        onChange={setSearchValue}
        value={searchValue}
        size="m"
        theme={theme}
        rounded={false}
        inputClassName={'tpg-c2 !pl-9 !text-tpg_light'}
      />
    </li>
  );

  return (
    <ul
      className={cn(
        'select__options',
        {
          select__options__searching: withSearch,
          select__options__empty: !filterdOptions.length,
          'bg-ultrablack': theme === 'dark',
          'bg-light': theme === 'light',
        },
        className
      )}
      style={{
        top: `${getMenuHeight()}px`,
      }}
    >
      {withSearch && menuPlacement === 'bottom' && renderSearchInput()}
      {withEmpty && (
        <li className="select__options__item" key="select-option-empty">
          <span
            className={cn(
              'text-dark_product select__options__item__label pl-4 tpg-c2',
              {
                'select__options__item__label__selected ': !value,
              }
            )}
            onClick={() => handleClick(null as T)}
          >
            Не выбрано
          </span>
        </li>
      )}
      {isAddMode && (
        <li
          className="select__options__item select__options__creation"
          key="select-option-creation"
        >
          {isOpenCreation ? (
            <TextInput
              isReset
              autoFocus
              placeholder="введите название"
              onChange={setNewOption}
              value={newOption}
              onEnter={handleEnter}
            />
          ) : (
            <button
              className="select__options__creation__btn"
              onClick={() => setOpenCreation(true)}
            >
              {creationText || '+ новый'}
            </button>
          )}
          {isCreating && <Spinner />}
        </li>
      )}
      {filterdOptions.map((option, index) => (
        <SelectItem
          key={`select-option-${option.label}-${index}`}
          value={value}
          option={option}
          searchValue={searchValue}
          depth={1}
          handleClick={handleClick}
          isExpandable={isExpandable}
        />
      ))}
      {!filterdOptions.length && (
        <div className="flex items-center justify-center text-tpg_base tpg-c2 pb-2 pt-2">
          {isLoading && <Spinner />}
          <span>{isLoading ? 'Загрузка...' : 'Нет данных'}</span>
        </div>
      )}
      <div className="sticky bottom-0 w-full">
        {withSearch && menuPlacement === 'top' && renderSearchInput()}
      </div>
    </ul>
  );
};

export default genericMemo(OptionsList);
