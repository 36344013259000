import { FC, useCallback, useState } from 'react';
import { deleteGroup, deleteUserToGroupLink, updateGroup } from 'api/access';
import { errorMessages, successMessages } from 'constants/errors';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';
import { GroupInfo, GroupMember } from 'interfaces';
import { accountSelector } from 'store/slices/auth/selectors';
import useResizeObserver from 'use-resize-observer';

import { IContextMenuTooltipItem } from 'components/ContextMenuTooltip';
import { NODE_INDENT } from 'components/Map/Tabs/Layers/utils';
import { Tree } from 'components/Tree';
import { Node } from 'components/Tree/Node';
import { TNode, TNodeData } from 'components/Tree/types';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import { handleNotify } from 'utils/notifications';

import GroupDetailsModal from '../Modals/GroupDetailsModal';
import GroupMemberDetailsModal from '../Modals/GroupMemberDetailsModal';

import { renderNodeArrow } from './NodeArrow';
import { renderNodeIcon } from './NodeIcon';
import { renderNodeName } from './NodeName';

export type GroupNode = TNode<GroupInfo, GroupMember, any, any>;

export type GroupNodeData = TNodeData<GroupInfo, GroupMember, any, any>;

interface AccessSidebarProps {
  data: GroupInfo[];
  activeNodes: GroupNode[];
  setActiveNodes: React.Dispatch<React.SetStateAction<GroupNode[]>>;
  onOperationSuccess: () => void;
}

const AccessSidebar: FC<AccessSidebarProps> = ({
  data,
  activeNodes,
  setActiveNodes,
  onOperationSuccess,
}) => {
  const { ref, height } = useResizeObserver();
  const account = useAppSelector(accountSelector);
  const isUserAdminOrSuperAdmin =
    account?.sysRole === 'superadmin' || account?.sysRole === 'admin';

  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    isGroupEditModal: false,
    isAddGroupModal: false,
  });

  const [processedNode, setProcessedNode] = useState<GroupNode | null>(null);

  const hasData = !!data.length;

  const updateChildrenState = useCallback(
    (node: GroupNode, isActive: boolean) => {
      const newNodes = isActive
        ? [...activeNodes, node, ...getAllChildren(node)]
        : activeNodes.filter((n) => !isNodeOrChild(n, node));
      return newNodes;
    },
    [activeNodes]
  );

  const getAllChildren = (node: GroupNode): GroupNode[] => {
    return (node.children || []).reduce((acc, child) => {
      return [...acc, child, ...getAllChildren(child)];
    }, [] as GroupNode[]);
  };

  const isNodeOrChild = (
    nodeToCheck: GroupNode,
    parentNode: GroupNode
  ): boolean => {
    if (nodeToCheck.id === parentNode.id) return true;
    return (parentNode.children || []).some((child) =>
      isNodeOrChild(nodeToCheck, child)
    );
  };

  const convertToTreeData = useCallback(
    (groups: GroupInfo[]): TNodeData<GroupInfo, GroupMember>[] => {
      return groups.map((group) => ({
        id: `${group.id}`,
        name: group.title,
        type: 'folder',
        isFolder: true,
        entity: group,
        info: { level: 1, parent: { id: '' } },
        state: { selected: activeNodes.some((n) => n.id === String(group.id)) },
        children: group.members.map((member) => ({
          id: `member-${group.id}-${member.id}`,
          name: member.verboseName,
          type: 'object',
          isFolder: false,
          entity: member,
          info: { level: 2, parent: { id: String(group.id) } },
          state: {
            selected: activeNodes.some((n) => n.id === String(member.id)),
          },
        })),
      }));
    },
    [activeNodes]
  );

  const handleNodeEditingChange = useCallback(
    (editing: boolean, node: GroupNode) => {
      if (node.data.isFolder) {
        editing ? node.edit() : node.reset();
      } else {
        node.isEditing && node.reset();
      }
    },
    []
  );

  const handleNodeEdit = useCallback((value: string, node: GroupNode) => {
    handleNotify(
      updateGroup({ id: Number(node.id), title: value }),
      successMessages.UPDATE_GROUP_SUCCESS,
      errorMessages.UPDATE_GROUP_ERROR
    ).then(() => onOperationSuccess());
  }, []);

  const handleModalOpen = useCallback(
    (modalType: keyof typeof modalState, node: GroupNode) => {
      setProcessedNode(node);
      setModalState((prev) => ({ ...prev, [modalType]: true }));
    },
    []
  );

  const handleAddGroup = () => {
    setModalState((prevState) => ({
      ...prevState,
      isAddGroupModal: true,
    }));
  };

  const handleNodeContextMenu = useCallback(
    (node: GroupNode): IContextMenuTooltipItem[] => {
      const commonItems = [
        {
          title: 'Удалить',
          onClick: () => handleModalOpen('isDeleteModalOpen', node),
        },
      ];

      if (node.data.isFolder) {
        return [
          {
            title: 'Добавить пользователя',
            onClick: () => handleModalOpen('isGroupEditModal', node),
          },
          { title: 'Переименовать', onClick: () => node.edit() },
          ...commonItems,
        ];
      }

      return commonItems;
    },
    []
  );

  const handleModalClose = useCallback((modalType: keyof typeof modalState) => {
    setProcessedNode(null);
    setModalState((prev) => ({ ...prev, [modalType]: false }));
  }, []);

  const handleNodeDelete = useCallback(async () => {
    if (processedNode) {
      processedNode.data.isFolder
        ? await handleNotify(
            deleteGroup(Number(processedNode.id)),
            successMessages.GROUP_DELETE_SUCCESS,
            successMessages.GROUP_DELETE_SUCCESS
          ).then(() => onOperationSuccess())
        : await handleNotify(
            deleteUserToGroupLink(
              Number(processedNode.parent?.id),
              Number(processedNode.data.id)
            ),
            successMessages.DELETE_USER_TO_GROUP_LINK_SUCCESS,
            errorMessages.DELETE_USER_TO_GROUP_LINK_ERROR
          ).then(() => onOperationSuccess());
      handleModalClose('isDeleteModalOpen');
    }
  }, [processedNode]);

  const handleNodeClick = useCallback(
    (e: unknown, node: GroupNode) => {
      if (node.data.isFolder) {
        setActiveNodes((prevNodes) => {
          const isCurrentlySelected = prevNodes.some((n) => n.id === node.id);
          if (isCurrentlySelected) {
            return [];
          } else {
            return [node];
          }
        });
      }
    },
    [activeNodes, updateChildrenState, setActiveNodes]
  );

  const isGroupInfo = (
    data: GroupInfo | GroupMember | undefined
  ): data is GroupInfo => {
    return (data as GroupInfo)?.members !== undefined;
  };

  return (
    <>
      <div className="absolute w-[474px] !h-[calc(100vh-100px)] bg-dark">
        <div className="flex flex-row justify-between items-center bg-light pl-4 pr-4 h-8">
          <span className="tpg-c2 text-tpg_base">Группы и пользователи</span>
          {isUserAdminOrSuperAdmin && (
            <Plus
              className="icon-container cursor-pointer"
              onClick={handleAddGroup}
            />
          )}
        </div>
        <div ref={ref} className="w-full h-full p-4 pb-12">
          {hasData ? (
            <Tree<GroupInfo, GroupMember, any, any>
              data={convertToTreeData(data)}
              height={height}
              indent={NODE_INDENT}
              disableEdit={false}
              disableDrag={true}
            >
              {(nodeRendererProps) => (
                <Node<GroupInfo, GroupMember, any, any>
                  renderArrow={renderNodeArrow}
                  renderIcon={renderNodeIcon}
                  renderName={renderNodeName}
                  getContextMenu={handleNodeContextMenu}
                  onClick={handleNodeClick}
                  onEditingChange={handleNodeEditingChange}
                  onEdit={handleNodeEdit}
                  className="w-full h-full pr-4 flex items-center"
                  {...nodeRendererProps}
                />
              )}
            </Tree>
          ) : (
            <span className="tpg-c1 text-tpg_base">
              {NOTHING_WAS_FOUND_MESSAGE}
            </span>
          )}
        </div>
      </div>

      {modalState.isDeleteModalOpen && (
        <ConfirmModal
          title="Вы уверены, что хотите удалить?"
          description="Вы можете потерять ценные данные."
          onConfirm={handleNodeDelete}
          onClose={() => handleModalClose('isDeleteModalOpen')}
        />
      )}
      {modalState.isGroupEditModal &&
        processedNode &&
        isGroupInfo(processedNode?.data.entity) && (
          <GroupMemberDetailsModal
            group={processedNode.data.entity}
            onClose={() => handleModalClose('isGroupEditModal')}
            onSuccess={onOperationSuccess}
          />
        )}
      {modalState.isAddGroupModal && (
        <GroupDetailsModal
          onSuccess={onOperationSuccess}
          onClose={() => handleModalClose('isAddGroupModal')}
        />
      )}
    </>
  );
};

export default AccessSidebar;
